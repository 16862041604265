import React, { useState } from 'react';
import SellCarFormWrapper from './style';

const SellCarForm = () => {

  return (
    <SellCarFormWrapper>
      <div className='row justify-content-center'>
        <div className='col-xl-6 col-lg-8 col-md-10'>
          <h2 className='heading'>Tell us about your car</h2>
          <p>Please give us some basics about yourself and the car you’d like to sell. We’ll also need details about the car’s title status as well as 6 photos that highlight the car’s exterior and interior condition.</p>
          <p>We’ll respond to your application within a business day. Once accepted, we’ll ask for more details and at least 50 high-res photos, then work with you to build a custom and professional listing and get the auction live</p>

          <div className='form-wrapper mt-4'>
            <span className='font-light'>Application Step 1 of 2</span>
            <div className='form-box mt-3'>
              <h3 className='form-heading'>Your Info</h3>
              <form>
                <label>Dealer or private party?</label>
                <div className='d-flex'>
                  <div>
                    <input type="radio" id="option1" name="options"/>
                    <label htmlFor="option1"  className='button'>Option 1</label>
                  </div>
                  <div>
                    <input type="radio" id="option2" name="options"/>
                    <label htmlFor="option2" className='button'>Option 2</label>
                  </div>
                </div>
                <div className='fields-wrapper'>
                  <div>
                    <label>Full Name</label><br />
                    <input type='text' />
                  </div>
                  <div>
                    <label>Contact Number</label><br />
                    <input type='text' />
                  </div>
                </div>
              </form>
            </div>
            <div className='form-box'>
              <h3 className='form-heading'>Car Details</h3>
              <form>
                <label>Dealer or private party?</label>
                <div className='fields-wrapper'>
                <input className='w-100' style={{ maxWidth: '80%' }} type='text' />
                  <button>Lookup</button>
                </div>
                <div className='fields-wrapper'>
                  <div>
                    <label>Year</label><br />
                    <input type='text' />
                  </div>
                  <div>
                    <label>Make</label><br />
                    <input type='text' />
                  </div>
                  <div>
                    <label>Model</label><br />
                    <input type='text' />
                  </div>
                </div>
                <div className='fields-wrapper'>
                  <div>
                    <label>Transmission</label><br />
                    <input type='text' />
                  </div>
                  <div>
                    <label>Mileage</label><br />
                    <input type='text' />
                  </div>
                </div>
                <div className='fields-wrapper'>
                  <div className='w-100'>
                    <label>Special options</label><br />
                    <textarea rows="3" />
                  </div>
                </div>
                <label>Dealer or private party?</label>
                <div className='d-flex'>
                  <div>
                    <input type="radio" id="option3" name="options1" />
                    <label htmlFor="option3" className="button">Option 1</label>
                  </div>
                  <div>
                    <input type="radio" id="option4" name="options1" />
                    <label htmlFor="option4" className="button">Option 2</label>
                  </div>
                </div>
                <label>Dealer or private party?</label>
                <div className='d-flex'>
                  <div>
                    <input type="radio" id="option5" name="options2" />
                    <label htmlFor="option5" className="button">Option 2</label>
                  </div>
                  <div>
                    <input type="radio" id="option6" name="options2" />
                    <label htmlFor="option6" className="button">Option 3</label>
                  </div>
                </div>
                <label>Dealer or private party?</label>
                <div className='d-flex'>
                  <div>
                    <input type="radio" id="option7" name="options3" />
                    <label htmlFor="option7" className="button">Option 1</label>
                  </div>
                  <div>
                    <input type="radio" id="option8" name="options3" />
                    <label htmlFor="option8" className="button">Option 2</label>
                  </div>
                </div>

                <div className='fields-wrapper'>
                  <div>
                    <label>Transmission</label><br />
                    <input type='text' />
                  </div>
                </div>
                <label>Dealer or private party?</label>
                <div className='d-flex'>
                  <div>
                    <input type="radio" id="option9" name="options4" />
                    <label htmlFor="option9" className="button">Option 1</label>
                  </div>
                  <div>
                    <input type="radio" id="option10" name="options4" />
                    <label htmlFor="option10" className="button">Option 2</label>
                  </div>
                </div>
              </form>
            </div>
            <button className='btn'>Next</button>
          </div>
        </div>
      </div>
    </SellCarFormWrapper>
  );
};

export default SellCarForm;
