import React from 'react'
import BuyerDashboardContent from '../../components/pageComponents/buyerDashboardContent'

function BuyerDashbaord() {
  return (
    <div>
      <BuyerDashboardContent />
    </div>
  )
}

export default BuyerDashbaord
