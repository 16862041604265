import React, { useEffect } from 'react'
import SlickSlider from '../../components/pageComponents/bannerSlider/SlickSlider'
import BootstrapTabs from '../../components/pageComponents/productTabs/BootstrapTabs'
import { useProductContext } from '../../contexts/ProductContext';
import { useLocation } from 'react-router-dom';

export default function Home() {
  const { productsContext, picturelinkContext, updateProductsContext, updatePictureLinkContext} = useProductContext();
  const location = useLocation();
  useEffect(() => {
    updateProductsContext([])
    // updatePictureLinkContext([])
  }, [location]);
  // console.log('productsContext', productsContext)
  return (
    <>
    {
      productsContext.length === 0 &&
      <SlickSlider />
    }
      <BootstrapTabs /> 
    </>
  )
}
