import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import {
  customofferstatus,
  getcustomOffer,
} from "../../backendServices/ApiCalls";
import { formatPrice } from "../../components/pageComponents/function/PriceFormat";

export default function CustomOffer() {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(true);
  // console.log(user.user_type)
  const [offerData, setOfferData] = useState([]);
  const getOffer = () => {
    let param = {
      user_type: user.user_type,
    };
    getcustomOffer(
      param,
      (response) => {
        setLoading(true);
        let { status } = response?.data;
        if (status === "success") {
          setOfferData(response?.data?.data);
          // console.log('response?.data?.data',response?.data?.data)
          setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );
  };

  const handleAcceptReject = (offerId, action) => {
    // console.log('offerId,action',offerId,action)
    let param = {
      offerId: offerId,
      action: action,
    };
    customofferstatus(
      param,
      (response) => {
        // console.log('response', response?.data?.message)
        getOffer();
      },
      (error) => {
        console.log(error?.response?.data);
        getOffer();
      }
    );
  };
  useEffect(() => {
    getOffer();
  }, []);

  if (loading) {
    return (
      <div class="d-flex justify-content-center my-3">
        <div
          className="spinner-border text-success spinner-border-lg"
          style={{ height: "3rem", width: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="mt-3">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Product Name</th>
              <th scope="col">Actual Amount</th>
              <th scope="col">Offer Amount</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {offerData?.map((offer, i) => {
              return (
                <tr key={i}>
                  <td>{offer.title}</td>
                  <td>{formatPrice(offer.amount)}</td>
                  <td>{formatPrice(offer.offerAmount)}</td>
                  <td>
                    {offer.offerstatus === "inprogress" &&
                    user.user_type === "Seller" ? (
                      <>
                        <button
                          className="btn text-white me-2 mb-1 mb-lg-0"
                          onClick={() => handleAcceptReject(offer.id, "accept")}
                          style={{ backgroundColor: "#3AB9C0" }}
                        >
                          Accept
                        </button>
                        <button
                          className="btn text-white btn-danger"
                          onClick={() => handleAcceptReject(offer.id, "reject")}
                        >
                          Reject
                        </button>
                      </>
                    ) : (
                      //  <span>{offer.offerstatus === 'accept' ? 'Accepted' : 'Rejected'}</span>
                      <span className="text-capitalize">
                        {offer.offerstatus + "ed"}
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {offerData === undefined && (
          <div className="text-center text-secondary">No Row</div>
        )}
      </div>
    </div>
  );
}
