import axios from "axios";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  deleteproduct,
  getbuyerproducts,
  getuserproducts,
  removeproductimages,
  updateproduct,
  updateproductimages,
} from "../../../backendServices/ApiCalls";
import Alert from "@mui/material/Alert";
import { useAuthContext } from "../../../contexts/AuthContext";
import ChatIcon from "@mui/icons-material/Chat";
import Chat from "../Chat/Chat";
import Modal from "../modal/Modal";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import ImageIcon from "@mui/icons-material/Image";
import { useDropzone } from "react-dropzone";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HideImageIcon from '@mui/icons-material/HideImage';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ProductManageContent() {
  const [products, setProducts] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [productId, setProductId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const [chatOneId, setChatOneId] = useState("");
  const [chatTwoId, setChatTwoId] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchSellerProducts = () => {
    getuserproducts(
      (response) => {
        if (response?.data?.status === "success") {
          setProducts(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const fetchBuyerProducts = () => {
    getbuyerproducts(
      (response) => {
        if (response?.data?.status === "success") {
          setProducts(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  useEffect(() => {
    if (user.user_type === "Seller") {
      fetchSellerProducts();
    } else {
      fetchBuyerProducts();
    }
  }, []);

  useEffect(() => {
    if (rowData) {
      setFormData({
        title: rowData.title,
        price: rowData.price,
        description: rowData.description,
      });
    }
  }, [rowData]);

  const columns = [
    {
      field: "title",
      headerName: "Product Title",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      renderCell: (params) => `$${params.value}`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "picture",
      headerName: "Product Detail",
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleOpen(params?.row?.id)}>
          View
        </Button>
      ),
    },
    {
      field: "action",
      headerName: "Update Detail",
      width: 150,
      renderCell: (params) => (
        <>
          <Tooltip title="Update" placement="top">
            <IconButton
              onClick={() =>
                handleActionUpdate(params.row.id, "update product")
              }
              sx={{ color: "warning.main" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Update Image" placement="top">
            <IconButton
              onClick={() => handleActionUpdate(params.row.id, "update images")}
              sx={{ color: "success.main" }}
            >
              <ImageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove Produt Images" placement="top">
            <IconButton
              onClick={() => handleActionUpdate(params.row.id, "remove images")}
              sx={{ color: "error.main" }}
            >
              <HideImageIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  if (user.user_type === "Buyer") {
    columns.push({
      field: "Chat",
      headerName: "Chat",
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="Chat with Seller" placement="top">
            <IconButton
              onClick={() => handleChat(params.row)}
              sx={{ color: "primary.main" }}
            >
              <ChatIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    });
  }
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleChat = (row) => {
    setChatOneId(row.buyerid);
    setChatTwoId(row.sellerid);
    openModal();
  };

  const handleOpen = (id) => {
    const rowToEdit = products.find((row) => row.id === id);
    setRowData(rowToEdit);
    navigate(`/product`, { state: { productId: id } });
  };

  const handleActionUpdate = (id, action) => {
    const rowToEdit = products.find((row) => row.id === id);
    setRowData(rowToEdit);
    if (action === "update product") {
      handleClickOpen();
      setUpdateStatus("update product");
    } else if (action === "update images") {
      handleClickOpen();
      setUpdateStatus("update images");
    } else if (action === 'remove images') {
      handleClickOpen();
      setUpdateStatus("remove images");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#f5343e",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let errors = {};
    // Simple validation check
    for (const field in formData) {
      if (formData[field] === "") {
        errors[field] = `${field} field is empty`;
      }
    }

    // Check if any errors exist
    if (Object.keys(errors).length > 0) {
      return; // Prevent form submission if any field is empty
    }

    // Create a FormData object to send both form data and images
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    let pid = rowData?.id;
    let params = { pid, ...formData };
    // formData.append("pid", pid);
    console.log("formDataToSend", params);
    // Call the registerUser function with the FormData object
    updateproduct(
      params,
      (response) => {
        console.log("response", response);
        if (response?.data?.status === "success") {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
          });
          handleClose();
          setFormData({ title: "", price: "", description: "" });
          fetchSellerProducts();
        } else if (response?.data?.status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
          handleClose();
          setFormData({ title: "", price: "", description: "" });
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
          handleClose();
          setFormData({ title: "", price: "", description: "" });
        }
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
        });
        setLoading(false);
      }
    );
  };

  const handleDrop = (acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleDrop,
  });

  const thumbs = files.map((file) => (
    <div
      key={file.name}
      style={{
        display: "inline-flex",
        borderRadius: 2,
        border: "1px solid #eaeaea",
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: "border-box",
      }}
    >
      <div style={{ display: "flex", minWidth: 0, overflow: "hidden" }}>
        <img
          src={file.preview}
          style={{ display: "block", width: "auto", height: "100%" }}
          alt="preview"
        />
      </div>
      <div
        // style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
        onClick={() => handleRemoveImage(file.name)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  ));

  const handleRemoveImage = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const dialogTitle = (() => {
    if (updateStatus === "update product") {
      return "Update Product Details";
    }
    if (updateStatus === "update images") {
      return "Update Product Images";
    }
    if (updateStatus === "remove images") {
      return "Remove Product Images";
    }
    return "Unknown Action";
  })();

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let errors = {};
    if (files.length < 4) {
      errors.images = "Please upload at least four images";
      Swal.fire({
        title: "Error",
        text: "Please upload at least four images",
        icon: "error",
      });
      setLoading(false);
      return; // Prevent form submission if less than 4 images are uploaded
    }

    // Create FormData object
    const formDataToSend = new FormData();

    // Append each file to FormData
    files.forEach((file, index) => {
      // Generate a unique filename using the current timestamp
      const imageName = `car-img-${Date.now()}-${index}.${file.name
        .split(".")
        .pop()}`;
      formDataToSend.append("images", file, imageName);
    });

    let pid = rowData?.id;
    formDataToSend.append('pid', pid)

    // Call updateproduct API with FormData object
    updateproductimages(
      formDataToSend,
      (response) => {
        console.log("response", response);
        if (response?.data?.status === "success") {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
          });
          handleClose();
          fetchSellerProducts();
          setFiles([]);
        } else if (response?.data?.status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
          handleClose();
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
          handleClose();
        }
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
        });
        setLoading(false);
      }
    );
  };

  const handleImageRemove = async (e) => {

    let params = {
      pid: rowData?.id
    }
    console.log("params", params);
    // Call updateproduct API with FormData object
    removeproductimages(
      params,
      (response) => {
        console.log("response", response);
        if (response?.data?.status === "success") {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
          });
          handleClose();
          fetchSellerProducts();
        } else if (response?.data?.status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
          handleClose();
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
          handleClose();
        }
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
        });
        setLoading(false);
      }
    );
  };



  return (
    <Box sx={{ height: 400, width: "100%" }}>
      {showAlert && (
        <Alert
          severity="info"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setShowAlert(false)}
            >
              Close
            </Button>
          }
        >
          This is an alert message
        </Alert>
      )}
      <DataGrid
        rows={products}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        autoHeight
        checkboxSelection={user.user_type === "Seller"}
        sx={gridDesign}
        components={{
          Toolbar: GridToolbar,
        }}
      />
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal}>
          <Chat userOne={chatOneId} userTwo={chatTwoId} />
        </Modal>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dialogTitle}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {updateStatus === "update product" ? (
            <form onSubmit={handleSubmit}>
              <Typography variant="body1">Product Title</Typography>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
                style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
              />
              <Typography variant="body1">Price</Typography>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                required
                style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
              />
              <Typography variant="body1">Description</Typography>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
                style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
              />
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Update"}
                </Button>
              </div>
            </form>
          ) : updateStatus === "update images" ? (
            <form onSubmit={handleImageSubmit}>
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #eeeeee",
                  borderRadius: "4px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                <Typography variant="body2" color="textSecondary">
                  Drag 'n' drop some files here, or click to select files
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  (Only *.jpeg, *.png images will be accepted)
                </Typography>
              </div>
              <aside
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 16,
                }}
              >
                {thumbs}
              </aside>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Update Images"}
                </Button>
              </div>
            </form>
          ) : updateStatus === "remove images" ? (
            <>
              <div>
                <Typography variant="body1">Are you sure you want to remove product images?</Typography>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button variant="contained" color="warning" onClick={handleImageRemove}>Yes</Button>
                <Button variant="contained" color="error" sx={{ ml: 2 }} onClick={handleClose}>No</Button>
              </div>
            </>
          ) : null}
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
