import React from 'react'
import ProductManageContent from '../../components/pageComponents/accountProduct/ProductManage'

function ManageProduct() {
  return (
    <div >
      <ProductManageContent />
    </div>
  )
}

export default ManageProduct
