// ProfileContent.js
import React, { useState } from "react";
import ProfileContentWrapper from "./style";
import Profile from "../../../assets/fake-user.jpeg";
import EditProfileModal from "../editProfileModal";
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from "../../../contexts/AuthContext";
import {
  createPayPalAccount,
  createStripeAccount,
} from "../../../backendServices/ApiCalls";
import Swal from "sweetalert2";

const ProfileContent = () => {
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [loading, setLoading] = useState(false);
  const [paypalEmail, setPayPalEmail] = useState("");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const navigate = useNavigate()

  const { user, dispatch } = useAuthContext();

  // =============================== Stripe function ==================================

  // const stripeAccount = () => {
  //   let param = {
  //     email: user.email,
  //     username: user.username,
  //     stripe_account: user.stripe_account
  //   }
  //   setLoading(true)
  //   createStripeAccount(param, (response) => {
  //     window.location.href = response?.data?.accountLinkUrl;
  //     setLoading(false)
  //   }, (error) => {
  //     console.log(error?.response?.data);
  //     setLoading(false)
  //   })
  // }

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const PayPalAccount = (e) => {
    e.preventDefault();
    if (!validateEmail(paypalEmail) && paypalEmail === "") {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        icon: "error",
      });
      return;
    }
    setLoading(true);
    let param = {
      paypalEmail,
      username: user.username,
    };
    createPayPalAccount(
      param,
      (response) => {
        localStorage.removeItem("token");
        dispatch({ type: "SET_LOGGED_OUT" });
        // window.location.reload();
        navigate('/')
        Swal.fire({
          title: 'Please Login In again',
          text: response?.data?.data,
          icon: "success",
        });

        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );
  };

  return (
    <ProfileContentWrapper>
      <div className="profile-content-wrapper">
        <div className="profile-content">
          {/* <img src={Profile} alt='profile' /> */}
          <img src={user.profilepictureurl} alt="profile" />
          <div className="user-info">
            <h2 className="username">UserName</h2>
            <p className="subtitle">{user?.username}</p>
          </div>
          <div>
            <button className="edit-btn" onClick={handleShow}>
              Edit Profile
            </button>
          </div>
        </div>
      </div>

      <div className="user-content">
        {user.user_type === "Seller" && (
          <>
            <p>
              Your PayPal Email: <b>{user.paypal_account}</b>
            </p>
            <form class="row g-3">
              <div className="col-auto">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter PayPal Email to attach account"
                  style={{ width: "18rem" }}
                  value={paypalEmail}
                  onChange={(e) => setPayPalEmail(e.target.value)}
                />
              </div>
              <div className="col-auto">
                <button
                  className="btn text-white"
                  style={{ backgroundColor: "#3AB9C0" }}
                  onClick={PayPalAccount}
                >
                  {loading === false ? (
                    user.paypal_account == "" ? (
                      <>PayPal Connected Account</>
                    ) : (
                      <>Change PayPal Connected Account</>
                    )
                  ) : (
                    <div
                      class="spinner-border text-success spinner-border-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </>
        )}
      </div>

      {/* <div className='user-content'>
        {
          user.user_type === 'Seller' &&
          <button className="btn text-white" style={{ backgroundColor: "#3AB9C0" }}>
            {
              loading === false ?
                <>PayPal Connected Account</>
                :
                <div class="spinner-border text-success spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
            }
          </button>
        }
      </div> */}
      <EditProfileModal show={showModal} handleClose={handleClose} />
    </ProfileContentWrapper>
  );
};

export default ProfileContent;
