import styled from "styled-components";

const ProductDetailsWrapper = styled.div`

h4{
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
}
.detail-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .carfax{
    padding: 16px 20px;
    background-color: #f1f3f4;
    border-radius: 6px;
    img{
      height: 21px;
      width: 100%;
    }
  }
  .end-time{
    font-size: 16px;
    color: #828282;
  }
}
.detail-table{
  padding: 24px 0;
  dl{
    margin-bottom: 0;
    flex-basis: 50%;
    display: grid;
    grid-template-columns: 33% 67%;
    &:first-of-type{
      border: 1px solid #ebebeb;
      border-bottom: 0;
      border-radius: 6px 0 0 6px;
    }
  }
  dt{
    padding-left: 16px;
    font-size: 15px;
    line-height: 41px;
    border-bottom: 1px solid #ebebeb;
    font-weight: 600;
    background: #fafafa;
    white-space: nowrap;
    border-right: 1px solid #ebebeb;
    &:first-of-type {
      border-top-left-radius: 6px;
    }
  }
  dd{
    padding-left: 16px;
    font-size: 15px;
    line-height: 41px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 0;
    .seller{
      display: flex;
      align-items: center;
      gap: 4px;
      img{
        height: 24px;
        width: 24px;
        object-fit: cover;
        border-radius: 100px;
      }
      a{
        text-decoration: underline;
        color: #212529;
      }
    }
  }
}

.dougs-take{
    padding: 24px;
    border-radius: 6px;
    margin-top: 24px;
    background-color: #f1f3f4;
    .user{
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
      img{
        height: 36px;
        width: 36px;
        object-fit: cover;
        border-radius: 100px;
      }
      h4{
        margin-bottom: 0;
      }
    }
  }
`

export default ProductDetailsWrapper;
