import React from 'react';
import ListingComponent from '../../components/pageComponents/listingsComponent';

const Listings = () => {
  return (
    <div className='pt-lg-5 mt-lg-5'>
      <ListingComponent/>
    </div>
  );
};

export default Listings;