export const formatPrice = (value, locale = 'en-US', currency = 'USD') => {
    // Determine if the price ends in .00
    const isWholeNumber = value % 1 === 0;

    // Format the price accordingly
    if (isWholeNumber) {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    } else {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    }
};
