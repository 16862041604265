import styled from "styled-components";

const GalleryWrapper = styled.div`
.action-btn{
  background-color: rgb(241, 243, 244);
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
}
.gallery-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gallery-header-actions{
  display: flex;
  gap: 8px;
  .action-btn{
    display: flex;
    align-items: center;
    gap: 6px;
    img{
      height: 16px;
    }
  }
}
@media(max-width: 991px){
  .gallery-header{
    flex-direction: column;
    justify-content: start;
    padding-bottom: 16px;
    .gallery-header-actions{
      width: 100%;
    }
  }
}

.gallery{
  .main-gallery{
    .main-img{
      aspect-ratio: 3/2.167;
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    .secondary-img{
      aspect-ratio: 2/1;
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    .img-overlay{
      position: absolute;
      bottom: -2px;
      right: 2px;
      aspect-ratio: 2/1;
      margin: 1px -2px;
      button{
        width: 100%;
        width: 100%;
        display: block;
        aspect-ratio: 2/1;
        border: none;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 20px;
        color: #fff;
        font-weight: 500;
      }
    }
    .exterior-badge{
      position: absolute;
      top: 4px;
      left: 6px;
      padding: 4px;
      border-radius: 4px;
      color: #fff;
      background-color: #262626;
      width: auto;
    }
    .interior-badge{
      position: absolute;
      top: 50.5%;
      left: 6px;
      padding: 4px;
      border-radius: 4px;
      color: #fff;
      background-color: #262626;
      width: auto;
    }
  }
}
.nav-tabs{
  position: sticky;
  top: 0;
  border-bottom: 0;
  .nav-item{
    .nav-link{
      font-size: 18px;
      color: #b7b7b7;
      transition: 0;
      border: none;
      &.active{
        background-color: transparent;
        border: none;
        color: #fff;
      }
    &:hover{
      border: none;
    }
    }
  }
}
.tabs-wrapper{
  display: flex;
  justify-content: space-between;
  // padding-top: 10px;
  // padding-bottom: 10px;
  position: sticky;
  align-items: center;
  top: 0;
  background-color: #000;
}
.image-container{
  overflow-y: scroll;
}
.image-modal{
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  overflow-y: scroll;
  img{
    aspect-ratio: 3/2;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

.close-btn{
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 32px;
  }
.preview-modal{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1);
  overflow-y: scroll;
  .tabs-wrapper{
    // padding: 20px 0;
    justify-content: end;
  }
  .img-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
  }
  img{
    height: calc(100vh - 70px);
  }
  .nav-arrows{
    display: flex;
    justify-content: space-between;
    width: 100%;
    img{
      height: 40px;
      position: absolute;
      top: 50%;
      border-radius: 50px;
      background-color: #b7b7b7;
      right: 0;
      &:first-child{
        left: 0;
      }
    }
  }
}


`

export default GalleryWrapper;