import React from 'react'
import SoldProductContent from '../../components/pageComponents/soldProduct'

function SoldProduct() {
  return (
    <div>
      <SoldProductContent />
    </div>
  )
}

export default SoldProduct
