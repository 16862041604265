import React from 'react'
import InprogressProductContent from '../../components/pageComponents/inprogressProduct'

function InprogressProduct() {
  return (
    <div>
      <InprogressProductContent />
    </div>
  )
}

export default InprogressProduct
