import styled from "styled-components";

const ProductSidebarWrapper = styled.div`
padding: 24px 0;
.heading{
  font-size: 18px;
  font-weight: 700;
  padding: 12px 0;
}
`

export default ProductSidebarWrapper;
