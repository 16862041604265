import React, { createContext, useState, useEffect, useContext } from 'react';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [productsContext, setProducts] = useState([]);
  const [picturelinkContext, setPictureLink] = useState([]);

  // Function to update products and picturelink
  const updateProductsContext = (newProducts) => {
    setProducts(newProducts);
  };

  const updatePictureLinkContext = (newPictureLink) => {
    setPictureLink(newPictureLink);
  };

  return (
    <ProductContext.Provider value={{ productsContext, picturelinkContext, updateProductsContext, updatePictureLinkContext }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  return useContext(ProductContext);
};
