import React, { useState } from "react";
import { customOffer } from "../../../backendServices/ApiCalls";
import Swal from "sweetalert2";

const CustomOfferModel = ({ closeModalForOffer, product, myid, partnerid }) => {
  const [offerAmount, setOfferAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const handleAmountChange = (e) => {
    setOfferAmount(e.target.value);
  };
  const handleSendOffer = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    let param = {
      offerAmount: offerAmount,
      productId: product.id,
      // myid: myid,
      partnerid: partnerid,
      amount: product.price,
    };
    customOffer(
      param,
      (response) => {
        setIsProcessing(false);
        let { status } = response?.data;
        if (status === "success") {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
          });
        } else if (status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
        }
        closeModalForOffer();
      },
      (error) => {
        setIsProcessing(false);
        console.log(error?.response?.data);
      }
    );
  };
  return (
    <div
      style={{ backgroundColor: "transparent", border: "none" }}
      className="modal-content buyer"
    >
      <div className="sign-up-container">
        <div className="sign-up-content">
          <div className="modal-header border-bottom-0">
            <p>Custom Offer</p>
            <button
              onClick={closeModalForOffer}
              type="button"
              className="btn-close"
            ></button>
          </div>
          <form onSubmit={handleSendOffer}>
            <div class="modal-body">
              <input
                type="number"
                className="form-control"
                value={product.price}
                readOnly
              />
              <label htmlFor="offer">Enter Amount your amount:</label>
              <input
                type="number"
                className="form-control"
                name="offer"
                required
                onChange={handleAmountChange}
              />
            </div>
            <div class="modal-footer">
              {isProcessing ? (
                <div
                  className="spinner-border text-success spinner-border-lg mt-2 me-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <button type="submit" class="btn btn-primary mt-2">
                  Send Offer
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomOfferModel;
