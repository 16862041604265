import React from 'react';
import SellCarForm from '../../components/pageComponents/sellCarForm';

const SellCar = () => {
  return (
    <div className='mt-5 pt-5'>
      <SellCarForm/>
    </div>
  );
};

export default SellCar;