import styled from "styled-components";

const ProductCardWrapper = styled.div `
a{
  text-decoration: none;
  color: #262626;
}
.product-card{
padding-bottom: 40px;
  .img-wrapper{
    position: relative;
    margin-bottom: 14px;
    .details{
      position: absolute;
      bottom: 8px;
      left: 8px;
      background-color: #262626;
      padding: 6px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      gap: 8px;
      .icon{
        height: 12px;
        width: 12px;
      }
      .text-light{
        opacity: .7;
      }
      span{
        color: #fff;
        font-size: 14px;
      }
    }
  }
  img{
    width: 100%;
    aspect-ratio: 3/2;
    border-radius: 4px;
    object-fit: cover;
  }
  .badge{
    padding: 6px;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .featured-badge{
    background-color: rgba(255, 255, 255, 0.6);
    color: rgb(38, 38, 38);
    position: absolute;
    z-index: 3;
  }
  .no-reserve-badge{
    background-color: #5da1ff;
    color: #fff;
    padding: 4px;
    font-size: 10px;
    margin-right: 4px;
  }
  .inspected-badge{
    background-color: #e4e7ed;
    color: #262626;
    padding: 4px;
    font-size: 10px;
    margin-right: 4px;
  }
  .badge-left{
    top: 8px;
    left: 8px;
  }
  .badge-right{
    top: 8px;
    right: 8px;
  }
  .main-details{
    p{
      font-size: 14px;
      margin-bottom: 0;
      &.auction-loc{
        color: #828282;
      }
    }
  }
  .auction-title{
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
  }
}
`

export default ProductCardWrapper