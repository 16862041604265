import React, { useState } from "react";
import axios from "axios";
import { useAuthContext } from "../../../contexts/AuthContext";
import { registerUser } from "../../../backendServices/ApiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faLock,
  faMobile,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "./signup.css";

// import './SignUp.css'; // Import CSS for custom styling

export default function SignUp({ closeModal }) {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
    mobile: "",
    user_type: "Buyer",
    address: "address",
  });

  const [formErrors, setFormErrors] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
    mobile: "",
    address: "",
  });

  const { dispatch } = useAuthContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password" || name === "confirmpassword") {
      // Check if passwords match
      if (name === "confirmpassword" && value !== formData.password) {
        setFormErrors({
          ...formErrors,
          confirmpassword: "Passwords does not match",
        });
      } else {
        setFormErrors({ ...formErrors, confirmpassword: "" });
      }
    } else {
      // Clear the error message when the user starts typing again
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    let errors = {};

    // Simple validation check
    for (const field in formData) {
      if (formData[field] === "") {
        errors[field] = `${field} field is empty`;
      }
    }

    if (formData.password !== formData.confirmpassword) {
      errors.confirmpassword = "Passwords does not match";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Prevent form submission if any field is empty or passwords don't match
    }

    registerUser(
      formData,
      (response) => {
        if (response?.data?.status === "success") {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              closeModal();
              navigate("/");
            }
          });
        } else if (response?.data?.status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
        }
        setIsProcessing(false);
      },
      (error) => {
        setIsProcessing(false);
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <div
      style={{ backgroundColor: "transparent", border: "none" }}
      className="modal-content buyer"
    >
      <div className="sign-up-content">
        <div className="modal-header border-bottom-0">
          <button
            onClick={closeModal}
            type="button"
            className="btn-close"
          ></button>
        </div>
        <h2 className="sign-up-title">Sign Up</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="Username"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.username && (
                <p className="error-message">{formErrors.username}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.email && (
                <p className="error-message">{formErrors.email}</p>
              )}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  name="firstname"
                  placeholder="First Name"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.firstname && (
                <p className="error-message">{formErrors.firstname}</p>
              )}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  name="lastname"
                  placeholder="Last Name"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.lastname && (
                <p className="error-message">{formErrors.lastname}</p>
              )}
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faMobile} />
                </div>
                <input
                  type="tel"
                  className="form-control"
                  id="mobile"
                  name="mobile"
                  placeholder="Mobile"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.mobile && (
                <p className="error-message">{formErrors.mobile}</p>
              )}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="Address"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.address && (
                <p className="error-message">{formErrors.address}</p>
              )}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faLock} />
                </div>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.password && (
                <p className="error-message">{formErrors.password}</p>
              )}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-group">
                <div className="input-icon">
                  <FontAwesomeIcon icon={faLock} />
                </div>
                <input
                  type="password"
                  className="form-control"
                  id="confirmpassword"
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  required
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.confirmpassword && (
                <p className="error-message">{formErrors.confirmpassword}</p>
              )}
            </div>

            <div
              style={{ textAlign: "center" }}
              className="col-lg-12 col-md-12 col-sm-12"
            >
              {isProcessing ? (
                <div
                  className="spinner-border text-success spinner-border-lg"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <button type="submit" className="btn btn-primary btn-block">
                  Register
                </button>
              )}
            </div>
          </div>
        </form>
        {/* <p className="sign-in-link">Already have an account? <a href="/auth/login">Sign in</a></p> */}
      </div>
    </div>
  );
}
