import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { registerUser } from "../../../backendServices/ApiCalls";
import {
  faUser,
  faEnvelope,
  faLock,
  faMobile,
  faDollarSign,
  faAlignLeft,
  faTimes,
  faLocation,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "./signup1.css";

export default function SellCar({ closeModal }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    address: "",
    title: "",
    msrprice: "",
    price: "",
    description: "",
    user_type: "Seller",
    paypal: "",
  });

  const [formErrors, setFormErrors] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    address: "",
    title: "",
    msrprice: "",
    price: "",
    description: "",
  });

  const [files, setFiles] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password" || name === "confirmpassword") {
      // Check if passwords match
      if (name === "confirmpassword" && value !== formData.password) {
        setFormErrors({
          ...formErrors,
          confirmpassword: "Passwords does not match",
        });
      } else {
        setFormErrors({ ...formErrors, confirmpassword: "" });
      }
    } else {
      // Clear the error message when the user starts typing again
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    let errors = {};

    // Simple validation check
    for (const field in formData) {
      if (formData[field] === "") {
        errors[field] = `${field} field is empty`;
      }
    }

    if (Object.keys(errors).length > 0 || files.length === 0) {
      if (files.length === 0) {
        errors.images = "Please upload at least one image";
        Swal.fire({
          title: "Error",
          text: "Please upload at least one image",
          icon: "error",
        });
      }
      setIsProcessing(false);
      setFormErrors(errors);
      return; // Prevent form submission if any field is empty or no images are uploaded
    }

		if (files.length > 5) {
        errors.images = "You can upload maximum 5 images!";
        Swal.fire({
          title: "Error",
          text: "You can upload maximum 5 images!",
          icon: "error",
        });
				setIsProcessing(false);
      setFormErrors(errors);
      return; // Prevent form submission if any field is empty or no images are uploaded
    }

    // Create a FormData object to send both form data and images
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    // Append each image file to the FormData object
    files.forEach((file, index) => {
      // Generate a unique filename using the current timestamp
      const imageName = `car-img-${Date.now()}-${index}.${file.name
        .split(".")
        .pop()}`;
      formDataToSend.append("images", file, imageName);
    });
    // Call the registerUser function with the FormData object
    registerUser(
      formDataToSend,
      (response) => {
        if (response?.data?.status === "success") {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              closeModal();
              navigate("/");
            }
          });
        } else if (response?.data?.status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
        }
        setIsProcessing(false);
      },
      (error) => {
        setIsProcessing(false);
        console.log(error?.response?.data);
      }
    );
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (files.length + acceptedFiles.length > 5) {
        Swal.fire({
          title: "Error",
          text: "You can only select a maximum of 5 images.",
          icon: "error",
        });
        return;
      }
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const thumbInner = {
    position: "relative",
    minWidth: 0,
    overflow: "hidden",
  };

  const removeIcon = {
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    padding: "4px",
    zIndex: 1,
  };

  // Add appropriate styling to the remove button
  const handleRemoveImage = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  // Render the remove button alongside each image
  const thumbs = files.map((file, index) => (
    <div style={thumb} key={index}>
      <div style={thumbInner}>
        <FontAwesomeIcon
          icon={faTimes}
          className="remove-icon"
          onClick={() => handleRemoveImage(index)}
        />
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Clean up function to revoke object URL
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="modal-content seller">
      <div className="sign-up-container">
        <div className="sign-up-content">
          <div className="modal-header border-bottom-0">
            <button
              onClick={closeModal}
              type="button"
              className="btn-close"
            ></button>
          </div>
          <h2 className="sign-up-title">Sign Up</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    placeholder="Username"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.username && (
                  <p className="error-message">{formErrors.username}</p>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.email && (
                  <p className="error-message">{formErrors.email}</p>
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstname"
                    placeholder="First Name"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.firstname && (
                  <p className="error-message">{formErrors.firstname}</p>
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastname"
                    placeholder="Last Name"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.lastname && (
                  <p className="error-message">{formErrors.lastname}</p>
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faMobile} />
                  </div>
                  <input
                    type="tel"
                    className="form-control"
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.mobile && (
                  <p className="error-message">{formErrors.mobile}</p>
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    placeholder="Address"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.address && (
                  <p className="error-message">{formErrors.address}</p>
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group mb-0">
                  <div className="input-icon mb-4">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className="form-control border-0 p-0">
                    <input
                      type="email"
                      className="form-control"
                      style={{ borderRadius: "0px 5px 5px 0px" }}
                      id="paypal"
                      name="paypal"
                      placeholder="Your PayPal"
                      required
                      onChange={handleInputChange}
                    />
                    <p className="fst-italic" style={{ fontSize: "10px" }}>
                      Your Paypal account for accepting payments.
                    </p>
                  </div>
                </div>
                {formErrors.paypal && (
                  <p className="error-message">{formErrors.paypal}</p>
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.password && (
                  <p className="error-message">{formErrors.password}</p>
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmpassword"
                    name="confirmpassword"
                    placeholder="Confirm Password"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.confirmpassword && (
                  <p className="error-message">{formErrors.confirmpassword}</p>
                )}
              </div>

              <hr />
              <h2 className="sign-up-title">Product Details</h2>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faAlignLeft} />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="Product Title"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.title && (
                  <p className="error-message">{formErrors.title}</p>
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="msrprice"
                    name="msrprice"
                    placeholder="MSR Price"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.msrprice && (
                  <p className="error-message">{formErrors.msrprice}</p>
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    name="price"
                    placeholder="Product Price"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.price && (
                  <p className="error-message">{formErrors.price}</p>
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <textarea
                    className="form-control"
                    name="description"
                    id="description"
                    cols="10"
                    rows="2"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                {formErrors.description && (
                  <p className="error-message">{formErrors.description}</p>
                )}
              </div>
              <div className="mt-3">
                <div className="row" style={{ cursor: "pointer" }}>
                  <div className="mb-3 text-center">
                    <div
                      style={{ border: "2px dashed #BBBBBB" }}
                      {...getRootProps({ className: "dropzone p-4" })}
                    >
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                  </div>
                </div>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="col-lg-12 col-md-12 col-sm-12"
              >
                {isProcessing ? (
                  <div
                    className="spinner-border text-success spinner-border-lg"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <button type="submit" className="btn btn-primary btn-block">
                    Register
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
