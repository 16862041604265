import React from 'react';
import SettingFormWrapper from './style';

const SettingForm = () => {
  return (
    <SettingFormWrapper>
      <h2 className='heading'>Settings</h2>
      <div className='form-wrapper'>
        <label>Account</label>
        <div className='form-line'>
          <label>Linked Accounts</label>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Not Set</p>
            <button className='light-btn'>Link Account</button>
          </div>
        </div>
        <div className='form-line'>
          <label>Password</label>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Not Set</p>
            <button className='light-btn'>Set Password</button>
          </div>
        </div>
      </div>
      <div className='form-wrapper'>
        <label>Payment info for bidding</label>
        <div className='form-line'>
          <button className='dark-btn'>Register to Bid</button>
        </div>
      </div>
      <div className='form-wrapper'>
        <label>C&B Email</label>
        <div className='form-line'>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Send me the Cars & Bids daily email</p>
            <div className='switch'>
              <input type="checkbox" id="checkbox1" />
              <label for="checkbox1"></label>
            </div>
          </div>
        </div>
        <div className='form-line'>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Send me the Cars & Bids week in review email</p>
            <div className='switch'>
              <input type="checkbox" id="checkbox1" />
              <label for="checkbox1"></label>
            </div>
          </div>
        </div>
      </div>
      <div className='form-wrapper'>
        <label>General Notifications</label>
        <div className='form-line'>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Notify me when I’m mentioned in auction comments</p>
            <div className='switch'>
              <input type="checkbox" id="checkbox1" />
              <label for="checkbox1"></label>
            </div>
          </div>
        </div>
        <div className='form-line'>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Notify me when someone replies to me in auction comments</p>
            <div className='switch'>
              <input type="checkbox" id="checkbox1" />
              <label for="checkbox1"></label>
            </div>
          </div>
        </div>
        <div className='form-line'>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Play sound when bids are placed (desktop only)</p>
            <div className='switch'>
              <input type="checkbox" id="checkbox1" />
              <label for="checkbox1"></label>
            </div>
          </div>
        </div>
        <div className='form-line'>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Notify me when I’m mentioned in communities</p>
            <div className='switch'>
              <input type="checkbox" id="checkbox1" />
              <label for="checkbox1"></label>
            </div>
          </div>
        </div>
        <div className='form-line'>
          <div className='d-flex justify-content-between align-items-center'>
            <p>Notify me when someone replies to me in a community</p>
            <div className='switch'>
              <input type="checkbox" id="checkbox1" />
              <label for="checkbox1"></label>
            </div>
          </div>
        </div>
      </div>

      <button className='light-btn mt-5'>Remove Account</button>
    </SettingFormWrapper>
  );
};

export default SettingForm;