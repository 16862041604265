import React, { useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import {
	Button,
	CircularProgress,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getpurchasedproducts } from '../../../backendServices/ApiCalls';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

function PurchasedProducdContent() {
	const [products, setProducts] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const navigate = useNavigate();


	async function fetchUserProducts() {
		getpurchasedproducts((response) => {
			if (response?.data?.status === 'success') {
				setProducts(response?.data?.data);
			}
		}, (error) => {
			console.log(error?.response?.data);
		});
	}

	useEffect(() => {
		fetchUserProducts();
	}, []);

	const columns = [
		{
			field: "title",
			headerName: "Product Title",
			width: 150,
			editable: true,
		},
		{
			field: "price",
			headerName: "Price",
			width: 150,
			renderCell: (params) => `$${params.value}`,
		},
		{
			field: "picture",
			headerName: "Product Detail",
			width: 150,
			renderCell: (params) => (
				<Button
					variant="contained"
					onClick={() => handleOpen(params?.row?.id)}
				>
					View
				</Button>
			),
		},
		{
			field: "productstatus",
			headerName: "Status",
			width: 150,
			// renderCell: (params) => `$${params.value}`,
		},
	];

	const gridDesign = {
		"& .MuiDataGrid-toolbarContainer": {
			"& .MuiButton-text": {
				fontSize: "13px !important",
				color: "#f5343e",
			},
			"& .MuiBadge-badge": {
				backgroundColor: "#074682",
			},
			"& .MuiInput-root": {
				borderRadius: 2,
				paddingLeft: 2,
				overflow: "hidden",
			},
		},
	};

	const handleOpen = (id) => {
		const rowToEdit = products.find((row) => row.id === id);
		navigate(`/product`, { state: { productId: id } })
	};

	return (
		<div className='mt-3'>
			<h4>Order History</h4>
			{showAlert && ( // Render Alert component only when showAlert is true
				<Alert
					severity="success"
					onClose={() => setShowAlert(false)} // Set showAlert to false when Alert is closed
				>
					Product deleted successfully.
				</Alert>
			)}
			<div>
				<div className="table-responsive-lg">
					<Box sx={{ height: 500, width: 1 }} textAlign="right">
						{/* <Link to="/add-product">
                <Button variant="contained" sx={{ marginTop: '-50px'}} href="!#">
                        Add Product
                </Button>
          </Link> */}
						{({ isSubmitting }) => (
							<div
								sx={{
									mt: "20%",
									ml: "45%",
									mb: "20%",
								}}
							>
								<CircularProgress />
							</div>
						)}
						<DataGrid
							initialState={{
								pagination: { paginationModel: { pageSize: 6 } },
							}}
							rows={products}
							getRowId={(row) => row.id}
							columns={columns}
							slots={{ toolbar: GridToolbar }}
							sx={gridDesign}
							pageSizeOptions={[6, 12, 18, 24, 30]}
							slotProps={{
								toolbar: {
									showQuickFilter: true,
									quickFilterProps: { debounceMs: 500 },
								},
							}}
						/>
					</Box>
				</div>
			</div>
		</div>
	)
}

export default PurchasedProducdContent