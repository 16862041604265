import React, { useEffect, useState } from "react";
import { Tab, Nav, Dropdown, Row, Col } from "react-bootstrap";
import ProductTabsWrapper from "./style";
// import ProductCard from '../productCard';
import CarImg1 from "../../../assets/car-img-1.jpeg";
import CarImg2 from "../../../assets/car-img-2.jpeg";
import ProductCard from "../productCard/ProductCard";
import {
  getallproducts,
  getdashboardsoldproducts,
  getsearchproducts,
} from "../../../backendServices/ApiCalls";
import { useProductContext } from "../../../contexts/ProductContext";
import { useLocation, useNavigate } from "react-router-dom";

const BootstrapTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("tab1");
  const [loading, setLoading] = useState(true);
  const [picturelink, setPictureLink] = useState([]);
  const [soldPictureLink, setSoldPictureLink] = useState([]);
  const [products, setProducts] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    productsContext,
    picturelinkContext,
    updateProductsContext,
    updatePictureLinkContext,
  } = useProductContext();
  // console.log('products in context hook', productsContext)
  // console.log('products', products)
  // console.log('products in context hook', picturelinkContext)
  useEffect(() => {
    if (productsContext.length>0) {
      setProducts(productsContext);
      setPictureLink(picturelinkContext);
    }
  }, [productsContext]);

  const handleTabSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };

  const GetAllProduct = () => {
    setLoading(true);
    getallproducts(
      (response) => {
        // console.log('response?.data?.data', response?.data?.data)
        setProducts(response?.data?.data);
        setPictureLink(response?.data?.imageURL);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(true);
      }
    );
  };

  const GetSoldProduct = () => {
    setLoading(true);
    getdashboardsoldproducts(
      (response) => {
        setSoldProducts(response?.data?.data);
        setSoldPictureLink(response?.data?.imageURL);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(true);
      }
    );
  };

  useEffect(() => {
    GetAllProduct();
    GetSoldProduct();
  }, []);
  
  useEffect(() => {
    updateProductsContext([])
    GetAllProduct();
    setSearchTerm("");
  }, [location]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === '') {
      GetAllProduct();
    GetSoldProduct();
    }
  };

  const handleSearchButton = () => {
    GetSearchProduct(searchTerm);
  };

  const GetSearchProduct = (productTitle) => {
    let params = {
      title: productTitle,
    };
    getsearchproducts(
      params,
      (response) => {
        setProducts(response?.data?.data);
        setPictureLink(response?.data?.imageURL);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  if (loading) {
    return (
      <div class="d-flex justify-content-center">
        <div
          className="spinner-border text-success spinner-border-lg"
          style={{ height: "3rem", width: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <ProductTabsWrapper>
      <div className="container">
        <Tab.Container activeKey={activeKey} onSelect={handleTabSelect}>
          <div className="tabs-header-wrapper">
            <div className="tabs-filters">
              <h3 className="tabs-heading">Featured Listings</h3>
              <div className="d-flex gap-2">
                {/* <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Month
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Current Month</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Last Month</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Transmissions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Option 3</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
                {/* <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Body Style
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Option 3</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
              </div>
            </div>
            <div
              className="position-relative d-none d-lg-block"
              style={{ width:"50%" }}
            >
              <input
                type="text"
                autoComplete="off"
                aria-autocomplete="list"
                aria-controls="react-autowhatever-1"
                className="form-control"
                placeholder="Search for parts (ex. Porsche, Ferrari, Audi)"
                name="search"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ width: "100%", paddingRight: "50px" }}
              />
              <button
                type="button"
                onClick={handleSearchButton}
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  height: "60%",
                  width: "15%",
                  backgroundColor: "#40C2C9",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "0 4px 4px 0",
                }}
              >
                Search
              </button>
            </div>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="tab1">Newly Listed</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tab2">Available</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tab3">Sold Out</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="tab1">
              <ProductCard
                products={products}
                picturelink={picturelink}
                xlCols={3}
                lgCols={4}
                smCols={6}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tab2">
              <ProductCard
                products={products}
                picturelink={picturelink}
                xlCols={3}
                lgCols={4}
                smCols={6}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tab3">
              <ProductCard
                products={soldProducts}
                picturelink={soldPictureLink}
                xlCols={3}
                lgCols={4}
                smCols={6}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </ProductTabsWrapper>
  );
};

export default BootstrapTabs;
