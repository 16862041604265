import React from 'react';
import FooterWrapper from './style';
import Stars from '../../../assets/stars.png';
import Logo from '../../../assets/logo.png';
import Youtube from '../../../assets/youtube.png';
import Insta from '../../../assets/insta.png';
import Facebook from '../../../assets/facebook.png';
import Tiktok from '../../../assets/tiktok.png';
import Twitter from '../../../assets/twitter.png';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { isAuth } = useAuthContext()
  return (
    <FooterWrapper>
      <div id="autos-footer" class="p-0 navbar navbar-expand-md navbar-light snipcss-oxnoS">
        <div class="container m-auto">
          <div class="footer-wrap">
            <div class="section brand">
              {
                isAuth ? '' :
                  <Link to="/" className="nav-link fw-medium">
                    <img style={{ width: 'auto', height: '70px', marginTop: '-25px' }} src={Logo} alt='logo' />
                  </Link>
              }
            </div>
            <div class="section social ">
              <p class="copyright">
                © Copyright 2024 throtyl
              </p>
            </div>
            <div class="section social ">
              <ul>
                <li style={{ cursor: 'pointer' }}>
                  <img src={Youtube} alt='youtube' />
                </li>
                <li>
                  <a href="http://instagram.com/throtylusa" target='_blank'>
                  <img src={Insta} alt='instagram' />
                  </a>
                </li>
                <li style={{ cursor: 'pointer' }}>
                  <img src={Facebook} alt='facebook' />
                </li>
                <li style={{ cursor: 'pointer' }}>
                  <img src={Tiktok} alt='tiktok' />
                </li>
                <li style={{ cursor: 'pointer' }}>
                  <img src={Twitter} alt='twitter' />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </FooterWrapper>
  );
};

export default Footer;
