// EditProfileModal.js
import React, { useState, useRef, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ProfileContentWrapper from './style';
import User from "../../../assets/fake-user.jpeg";
import Swal from 'sweetalert2'
import { updateProfilePicture } from '../../../backendServices/ApiCalls';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const EditProfileModal = ({ show, handleClose }) => {
  const { user, dispatch } = useAuthContext()
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate()
  const [isBtnLoading, setBtnIsLoading] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        setImage(selectedFile);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
          icon: 'error',
        });
      }
    }
  };

  const handleUpload = () => {
    // Trigger the click event of the file input
    fileInputRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnIsLoading(true);
    if (!image) {
      Swal.fire({
        title: 'Error',
        text: 'Invalid file type. Please select an image file.',
        icon: 'error',
      });
      setBtnIsLoading(false);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result; // Extract base64 encoded string
      const formData = new FormData();
      formData.append('image', base64Data);

      updateProfilePicture(formData, (response) => {
        setBtnIsLoading(false);
        if (response?.data?.status === "error") {
          Swal.fire({
            title: 'Error',
            text: response?.data?.message,
            icon: 'error',
          });
        }
        else if (response?.data?.status === "success") {
          Swal.fire({
            title: 'Please Login In again',
            text: response?.data?.message,
            icon: 'success',
          });
          localStorage.removeItem("token");
        dispatch({ type: "SET_LOGGED_OUT" });
        navigate('/')
          // setloginUserData((prevState) => ({
          // 	...prevState,
          // 	profilepictureurl: response?.data?.pictureurl,
          // }));
        }
        else {
          Swal.fire({
            title: 'Success',
            // text: 'Something went wrong please try again later',
            icon: 'success',
          });
        }
        // window.location.reload();
      }, (error) => {
        setBtnIsLoading(false);
        Swal.fire({
          title: 'Success',
          // text: 'Something went wrong please try again later',
          icon: 'success',
        });
      });
    };

    reader.readAsDataURL(image);
    // window.location.reload();
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <ProfileContentWrapper>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='profile-wrapper'>
            <img className='user' src={image ? URL.createObjectURL(image) : user.profilepictureurl} alt='user' />
            <button className='edit-btn' onClick={handleUpload}>Choose a profile photo</button>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/*" onChange={handleImageChange} />
          </div>
          {/* <div className='pt-3'>
            <label className='d-flex'>Bio</label>
            <textarea className='w-100' rows="4" />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          {
            isBtnLoading === false ?
              <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
              :
              <Button variant="primary">
                <div class="spinner-border text-success spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </Button>
          }
        </Modal.Footer>
      </ProfileContentWrapper>
    </Modal>
  );
};

export default EditProfileModal;
