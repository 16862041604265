import React from "react";

function WhatisThrotyl() {
  return (
    <>
      <div className="text-center">
        <h1
          className="pb-1"
          style={{ borderBottom: "4px solid #40C2C9", display: "inline" }}
        >
          What's throtyl?
        </h1>
      </div>
      <div className="mt-5" style={{ display: "flex", alignItems: "center" }}>
        <img src="/images/icon-1.png" width={70} alt="icon 1" />
        <div style={{ marginLeft: "10px", marginTop: 10  }}>
          <h3><b>Cool Car Parts</b></h3>
          <p>Buy and sell your amazing car parts, upgrade your car today.</p>
        </div>
      </div>
      <div className="mt-5" style={{ display: "flex", alignItems: "center" }}>
        <img src="/images/icon-2.png" width={70} alt="icon 2" />
        <div style={{ marginLeft: "10px", marginTop: 10  }}>
          <h3><b>Low Fees</b></h3>
          <p>
            Sellers pay a 10% fee of total sale price once their item sells, and
            are responsible for shipping to the buyer.
          </p>
        </div>
      </div>
      <div className="mt-5" style={{ display: "flex", alignItems: "center" }}>
        <img src="/images/icon-3.png" width={70} alt="icon 3" />
        <div style={{ marginLeft: "10px", marginTop: 10 }}>
          <h3><b>Easy to Use</b></h3>
          <p>
            Snap a few pictures, come up with a fair price, describe your part &
            sell it today.
          </p>
        </div>
      </div>
    </>
  );
}

export default WhatisThrotyl;
