import React from 'react'
import PendingProductsContent from '../../components/pageComponents/pendingProducts'

function PendingProduct() {
  return (
    <div>
      <PendingProductsContent />
    </div>
  )
}

export default PendingProduct