import React, { useEffect, useState } from 'react'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping, faCartArrowDown, faCartShopping, faCircleXmark, faSackDollar, faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { buyerdashboarddata } from '../../../backendServices/ApiCalls'
import { Link } from 'react-router-dom'

function BuyerDashboardContent() {
	const [dashboard, setDashboard] = useState([])

	const GetDashboardData = () => {
		buyerdashboarddata((response) => {
			setDashboard(response?.data?.data)
		}, (error) => {
			console.log(error?.response?.data);
		})
	}

	useEffect(() => {
		GetDashboardData();
	}, []);

	return (
		<div classNameName='container-fluid'>
			<section id="minimal-statistics">
				<div className="row mt-3 grid gap-0 row-gap-3">
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
						<Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faCartArrowDown} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalAvailableProducts}</h3>
											<span>Available Products</span>
										</div>
									</div>
								</div>
							</div>
						</Link>
						</div>
					</div>
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
						<Link to="/account/manage" style={{ color: 'inherit', textDecoration: 'none' }}>
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faCartShopping} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalInprogressProducts}</h3>
											<span>Inprogress Products</span>
										</div>
									</div>
								</div>
							</div>
						</Link>
						</div>
					</div>
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
						<Link to="/account/purchased-product" style={{ color: 'inherit', textDecoration: 'none' }}>
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faBagShopping} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalSoldProducts}</h3>
											<span>Purchased Products</span>
										</div>
									</div>
								</div>
							</div>
						</Link>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default BuyerDashboardContent
