import React, { useEffect, useState } from 'react'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping, faCartArrowDown, faCartShopping, faCircleXmark, faSackDollar, faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { dashBoardApi } from '../../../backendServices/ApiCalls'
import { useAuthContext } from '../../../contexts/AuthContext'

function SellerDashboardContent() {
	const [dashboard, setDashboard] = useState([])
	const { user } = useAuthContext()

	const GetDashboardData = () => {
		dashBoardApi((response) => {
			setDashboard(response?.data?.data)
		}, (error) => {
			console.log(error?.response?.data);
		})
	}

	useEffect(() => {
		GetDashboardData();
	}, []);

	return (
		<div classNameName='container-fluid'>
			<section id="minimal-statistics">
				<div className="row mt-3 grid gap-0 row-gap-3">
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faSackDollar} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalEarning === null ? "0" : dashboard?.totalEarning}</h3>
											<span>Total Earning</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faCartShopping} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalPandingProducts}</h3>
											<span>Pending Product</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faBagShopping} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalApprovedProducts}</h3>
											<span>Approved Product</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				{/* </div>

				<div className="row mt-4  mb-5"> */}
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faCircleXmark} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalrejectedProducts}</h3>
											<span>Rejected Product</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faCartArrowDown} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalInprogressProducts}</h3>
											<span>Inprogress Product</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-4 col-sm-6 col-12">
						<div className="card">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<FontAwesomeIcon icon={faSquareCheck} className="float-left icon" />
										</div>
										<div className="media-body text-right custom-text-align">
											<h3>{dashboard?.totalSoldProducts}</h3>
											<span>Sold Product</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default SellerDashboardContent
