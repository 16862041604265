import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Col, Row } from 'react-bootstrap';
import BannerSliderWrapper from './style';
import ClockIcon from '../../../assets/clock-icon.png';
import CarImg1 from "../../../assets/car-img-1.jpeg";
import CarImg2 from "../../../assets/car-img-2.jpeg";
import { getproductsfordemo } from '../../../backendServices/ApiCalls';

const SlickSlider = () => {
  const [loading, setLoading] = useState(true)
  const [picturelink, setPictureLink] = useState([])
  const [products, setProducts] = useState([])
  const [slides, setSlides] = useState([]);

  // const images =  JSON.parse(products[0]?.images)

  const GetProduct = () => {
    setLoading(true);
    getproductsfordemo((response) => {
      // Assuming your data is stored in an array called 'dataArray'
      // console.log('response.data.data', response.data.data)
      // const filteredData = response.data.data.filter(item => item.slider === "on");
      const filteredData = response?.data?.data
      setProducts(filteredData)
      setPictureLink(response?.data?.imageURL)
      setLoading(false)
    }, (error) => {
      console.log(error?.response?.data);
      setLoading(false)
    })
  }

  useEffect(() => {
    GetProduct();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      const createImageUrls = (imageNames) => imageNames.map(name => picturelink + name);

      const updatedSlides = products.map(product => {
        const images = createImageUrls(JSON.parse(product.images).imageNames);
        return {
          image: images[0],
          // title: product.title || '',
          // featured: product.featured || false,
          // inspected: product.inspected || false,
          // link: '/',
          // reserve: product.reserve || false,
          // days: product.days || 1,
          // bid: product.bid || 50000,
          // additionalImages: images,
          additionalImages: images[0],
        };
      });

      setSlides(updatedSlides);
    }
  }, [products, picturelink]);

  // useEffect(() => {
  //   if (products.length > 0) {
  //     const slide1 = picturelink + JSON.parse(products[0]?.images)?.imageNames[0];
  //     console.log('slide1', slide1)
  //     const slide2 = picturelink + JSON.parse(products[0]?.images)?.imageNames[1];
  //     const slide3 = picturelink + JSON.parse(products[0]?.images)?.imageNames[2];
  //     const slide4 = picturelink + JSON.parse(products[0]?.images)?.imageNames[3];

  //     const updatedSlides = [
  //       {
  //         image: slide1,
  //         title: '',
  //         featured: false,
  //         inspected: true,
  //         link: '/',
  //         reserve: false,
  //         days: 5,
  //         bid: 50000,
  //         additionalImages: [slide1, slide2, slide3, slide4]
  //       },
  //       {
  //         image: slide2,
  //         title: '',
  //         featured: true,
  //         link: '/',
  //         reserve: true,
  //         inspected: false,
  //         days: 1,
  //         bid: 50000,
  //         additionalImages: [slide3, slide4, slide1, slide2]
  //       },
  //       {
  //         image: slide3,
  //         title: '',
  //         featured: true,
  //         link: '/',
  //         reserve: true,
  //         inspected: false,
  //         days: 1,
  //         bid: 50000,
  //         additionalImages: [slide4, slide1, slide2, slide3]
  //       },
  //       {
  //         image: slide4,
  //         title: '',
  //         featured: true,
  //         link: '/',
  //         reserve: true,
  //         inspected: false,
  //         days: 1,
  //         bid: 50000,
  //         additionalImages: [slide4, slide2, slide3, slide4]
  //       },
  //     ];

  //     setSlides(updatedSlides);
  //   }
  // }, [products]);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    cssEase: 'linear'
  };

  // if (loading) {
  //   return (
  //     <div class="d-flex justify-content-center">
  //       <div className="spinner-border text-success spinner-border-lg" style={{height:'3rem',width:"3rem"}} role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <BannerSliderWrapper>
      <div className='container mt-2'>
        <Slider {...settings}>
          {slides.map((slide, index) => {
            return (
              <div key={index}>
                <Row className='g-1'>
                  <Col lg={12}>
                    <div className='main-product-img-wrapper'>
                      <img className='product-img' src={slide.image} alt='car-img' />
                      <div className='title-wrapper'>
                        <h2 className='modal-title'>{slide.title}</h2>
                      </div>
                      <div className='slide-details'>
                        <div className='d-flex align-items-center gap-1'>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={5}> */}
                    {/* <Row className='g-1'> */}
                      {/* {slide.additionalImages.map((img, idx) => {
                        return (
                          <Col xs={6} key={idx}><img className='product-img-sm' src={img} alt='car-img' /></Col>
                        )
                      })} */}
                          {/* <Col xs={6} ><img className='product-img-sm' src={slide.additionalImages} alt='car-img' /></Col>
                          <Col xs={6} ><img className='product-img-sm' src={slide.additionalImages} alt='car-img' /></Col>
                          <Col xs={6} ><img className='product-img-sm' src={slide.additionalImages} alt='car-img' /></Col>
                          <Col xs={6} ><img className='product-img-sm' src={slide.additionalImages} alt='car-img' /></Col> */}
                    {/* </Row> */}
                  {/* </Col> */}
                </Row>
              </div>
            )
          })}
        </Slider>
      </div>
    </BannerSliderWrapper>
  );
};

export default SlickSlider;
