import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from '../../components/pageComponents/header/Navbar';
import Footer from '../../components/pageComponents/footer/Footer';
import Home from './Home';
import About from './About';
import Product from './Product';
import Profile from './Profile';
import Settings from './Settings';
import Listings from './Listings';
import SellCar from './SellCar';
import Sidebar from '../../components/pageComponents/sidebar';
import SellerDashboard from './SellerDashboard';
import ManageProduct from './ManageProduct';
import PendingProduct from './PendingProduct';
import ApprovedProduct from './ApprovedProduct';
import InprogressProduct from './InprogressProduct';
import Rejectedproduct from './Rejectedproduct';
import SoldProduct from './SoldProduct';
import NotificationChat from './NotificationChat';
import CustomOffer from './CustomOffer';
import BuyerDashbaord from './BuyerDashboard';
import PurchasedProduct from './PurchasedProduct';
import AddProduct from './AddProduct';
import { useAuthContext } from '../../contexts/AuthContext';
import EmailVerify from '../../components/pageComponents/header/EmailVerify';
import WhatisThrotyl from './WhatisThrotyl';

export default function Index() {
  const location = useLocation();
  const { user, isAuth } = useAuthContext()

  // Check if the current route is one of the pages where you want to display the sidebar
  const showSidebar = ['/account/listings', '/account/', '/profile', '/account/settings','/account/add','/account/manage','/account/seller-dashboard', "/account/notification","/account/customOffer", "/account/pending-product", "/account/approved-product", "/account/rejected-product", "/account/inprogress-product", "/account/sold-product", "/account/buyer-dashboard", "/account/purchased-product", '/account/profile' ].includes(location.pathname);

  if (!isAuth && showSidebar && !user) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <Navbar />
      <div className="main" style={{minHeight:'60vh'}}>

      <div className='container'>
        <div className='row'>
          {showSidebar && (
            <div className='col-lg-3'>
              <Sidebar />
            </div>
          )}
          <div className={showSidebar ? 'col-lg-9' : 'col-12'}>
            <div className='content'>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/what-is-throtyl' element={<WhatisThrotyl />} />
                <Route path='/product/:id' element={<Product />} />
                <Route path='/account/profile' element={<Profile />} />
                <Route path='/account/seller-dashboard' element={<SellerDashboard />} />
                <Route path='/account/buyer-dashboard' element={<BuyerDashbaord />} />
                <Route path='/account/listings' element={<Listings />} />
                <Route path='/account/settings' element={<Settings />} />
                <Route path='/account/add' element={<AddProduct />} />
                <Route path='/account/manage' element={<ManageProduct />} />
                <Route path='/account/pending-product' element={<PendingProduct />} />
                <Route path='/account/approved-product' element={<ApprovedProduct />} />
                <Route path='/account/rejected-product' element={<Rejectedproduct />} />
                <Route path='/account/inprogress-product' element={<InprogressProduct />} />
                <Route path='/account/sold-product' element={<SoldProduct />} />
                <Route path='/sell-car' element={<SellCar />} />
                <Route path='/account/notification' element={<NotificationChat />} />
                <Route path='/account/customOffer' element={<CustomOffer />} />
                <Route path='/account/purchased-product' element={<PurchasedProduct />} />
                <Route path='/login/:token/:email' element={<EmailVerify />} />
                <Route path="*" element={<h1>404</h1>} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}
