// ProductCard.js
import React from "react";
import ProductCardWrapper from "./style";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { formatPrice } from "../function/PriceFormat";

const ProductCard = ({ products, picturelink, smCols, lgCols, xlCols }) => {
  const navigate = useNavigate();

  // , { state: { productId: product?.id } }

  return (
    <ProductCardWrapper>
      <Row>
        {products?.map((product, index) => {
          return (
            <Col xl={xlCols} lg={lgCols} sm={smCols} key={index}>
              <Link to={`/product/${product.id}`}>
                <div className="product-card" style={{ cursor: "pointer" }}>
                  <div className="img-wrapper">
                    {/* Logging product.images */}
                    <img
                      src={
                        picturelink +
                        (product?.images
                          ? JSON.parse(product?.images)?.imageNames[0]
                          : "")
                      }
                      alt="car"
                    />
                    {product?.featured && (
                      <div className="badge featured-badge badge-right">
                        FEATURED
                      </div>
                    )}
                    {product?.productstatus !== "sold" && (

                    <div className="details">
                        <div className="d-flex gap-1">
                          <span className="text-white"> <s style={{ color: '#40C2C9' }}>{formatPrice(product?.msrprice)}</s> </span>
                          <span>/ {formatPrice(product?.price)}</span>
                        </div>
                    </div>
                  )}
                  </div>
                  <div className="main-details">
                    <span className="auction-title">{product?.title}</span>
                    <p className="auction-subtitle">
                      <div className="badge no-reserve-badge">Detail</div>
                      {product?.description?.length > 80
                        ? product?.description.slice(0, 80) + "..."
                        : product?.description}
                    </p>
                    <p className="auction-loc">{product?.location}</p>
                  </div>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>
    </ProductCardWrapper>
  );
};

export default ProductCard;
