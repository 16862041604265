import React from 'react'
import ApprovedProductsContent from '../../components/pageComponents/approvedProduct'

function ApprovedProduct() {
  return (
    <div className='mt-3'>
      <ApprovedProductsContent />
    </div>
  )
}

export default ApprovedProduct
