import React, { useEffect, useState } from "react";
import ProductDetailsWrapper from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../contexts/AuthContext";
import Swal from "sweetalert2";
import Modal from "../modal/Modal";
import Login from "../header/Login";
import { buyproduct, paymentIntent, url } from "../../../backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import Chat from "../Chat/Chat";
import SellIcon from "@mui/icons-material/Sell";
import CustomOfferModel from "../customOffer/CustomOfferModel";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { formatPrice } from "../function/PriceFormat";

const ProductDetails = ({ products, picturelink, customOffer }) => {
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const { user, isAuth } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOffer, setIsOpenOffer] = useState(false);
  const [chatOneId, setChatOneId] = useState("");
  const [chatTwoId, setChatTwoId] = useState("");
  const navigate = useNavigate();
  const [loadingBuy, setLoadingBuy] = useState(false);
  if (customOffer !== undefined) {
    products[0].price = customOffer;
  }
  const [message, setMessage] = useState("");
  const openModalLogin = () => {
    setIsOpenLogin(true);
  };
  const closeModalLogin = () => {
    setIsOpenLogin(false);
  };
  // =============================== Stripe function ==================================

  // const handlePayment = async () => {
  //   if (products[0].stripe_account === '') {
  //     Swal.fire({
  //       title: 'Error',
  //       text: 'Something went wrong',
  //       icon: 'error',
  //     });
  //   } else {
  //     setLoadingBuy(true)
  //     let param = {
  //       stripe_account: products[0].stripe_account,
  //       amount: products[0]?.price,
  //       name: products[0]?.title
  //     }
  //     paymentIntent(param, (response) => {
  //       // if (response?.data?.accountLinkUrl) {
  //       //   window.location.href = response?.data?.accountLinkUrl;
  //       // }

  //       if (response?.data?.message?.code) {
  //         Swal.fire({
  //           title: 'Error',
  //           text: response?.data?.message?.code,
  //           icon: 'error',
  //         });
  //         navigate('/account/profile')
  //       }
  //       else {
  //         window.location.href = response?.data?.payment_url;
  //       }
  //       setLoadingBuy(false)
  //     }, (error) => {
  //       console.log(error?.response?.data);
  //       setLoadingBuy(false)
  //     })
  //   }
  // };

  const handelBuyProduct = (paymentHistory, tenPercent) => {
    // if (isAuth == false) {
    //   openModalLogin()
    // }
    if (isAuth == true) {
      let params = {
        userId: user.userid,
        productId: products[0].id,
        amount: products[0].price,
        sellerid: products[0].userid,
        // grossAmount:paymentHistory.gross_amount.value,
        paypalFee: paymentHistory.paypal_fee.value,
        sendAmount: paymentHistory.net_amount.value,
        adminAmount: tenPercent,
      };
      setLoadingBuy(false);
      buyproduct(
        params,
        (response) => {
          let { status } = response?.data;
          if (status === "success") {
            Swal.fire({
              title: "Product Buy",
              text: response?.data?.message,
              icon: "success",
            });
            navigate("/account/manage");
          } else if (status === "error") {
            Swal.fire({
              title: "Error",
              text: response?.data?.message,
              icon: "error",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Something went wrong. Please try again later.",
              icon: "error",
            });
          }
        },
        (error) => {
          console.log(error?.response?.data);
        }
      );
      Swal.fire({
        // title: 'Error',
        text: "Buy Product.",
        icon: "success",
      });
    }
  };
  const openModal = () => {
    if (isAuth == false) {
      openModalLogin();
    } else {
      setChatOneId(user.userid);
      setChatTwoId(products[0].userid);
      setIsOpen(true);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const closeModalForOffer = () => {
    setIsOpenOffer(false);
  };
  const OpenModelForOffer = () => {
    if (isAuth == false) {
      openModalLogin();
    } else {
      setIsOpenOffer(true);
      setChatOneId(user.userid);
      setChatTwoId(products[0].userid);
    }
  };
  const generateRandomNumberExceptDigit = () => {
    let randomNumber;
    const min = 1000000000; // 10 digits
    const max = 9999999999; // 10 digits
    const excludedDigit = 5;
    do {
      randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (
      randomNumber.toString().includes(excludedDigit.toString()) ||
      randomNumber.toString().length !== 10
    );
    return randomNumber;
  };

  const initialOptions = {
    "client-id":
      "AephCpPI8vLwjedtBQLST-OcxfyCVttKi5XiB08yE8RFWX0A3YgwMJMbNsDK5c4B8mqjVx_rA9v6LY2A",
    "enable-funding": "card",
    "disable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const createOrder = async () => {
    const randomNum = generateRandomNumberExceptDigit();
    // const productId = products[0]?.id || randomNum.toString()
    const productId = products[0]?.id;
    // console.log('products[0]?.id',products[0]?.id)
    // const productId = randomNum.toString()
    try {
      const response = await fetch(`${url}orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: productId,
              quantity: "1",
            },
          ],
          productAmount: products[0]?.price,
          // productAmount: tenPercent,
        }),
      });

      const orderData = await response.json();

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      setMessage(`Could not initiate PayPal Checkout...${error}`);
    }
  };

  const onApprove = async (data, actions) => {
    // console.log('data',data)
    // console.log('actions',actions)
    try {
      const response = await fetch(`${url}orders/${data.orderID}/capture`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const orderData = await response.json();

      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        return actions.restart();
      } else if (errorDetail) {
        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
      } else {
        const transaction = orderData.purchase_units[0].payments.captures[0];
        setMessage(
          `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
        );
        const paymentHistory =
          orderData.purchase_units[0].payments.captures[0]
            .seller_receivable_breakdown;
        setLoadingBuy(true);
        setTimeout(() => {
          handlePayment(paymentHistory);
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      setMessage(`Sorry, your transaction could not be processed...${error}`);
    }
  };

  const handlePayment = async (paymentHistory) => {
    const randomNum = generateRandomNumberExceptDigit();
    const senderBatchId = randomNum;
    const receiverEmail = products[0]?.paypal_account;
    const totalAmount = paymentHistory.net_amount.value;
    let tenPercent = totalAmount * 0.1;
    tenPercent = tenPercent.toFixed(2);
    const ninetyPercent = totalAmount * 0.9;
    const amountValue = Math.round(ninetyPercent * 100) / 100;
    paymentHistory.net_amount.value = amountValue;
    // paymentHistory.adminAmount = { value: adminAmount, currency: paymentHistory.net_amount.currency }; // Adding adminAmount to paymentHistory object
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        senderBatchId,
        receiverEmail,
        amountValue,
      }),
    };

    try {
      const response = await fetch(
        `${url}paypal-partner-Payment`,
        requestOptions
      );
      const result = await response.json();
      handelBuyProduct(paymentHistory, tenPercent);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ProductDetailsWrapper>
      {/* <Message content={message} /> */}
      <div className="product-details mt-5 mt-md-0">
        <div>
          <h3 className="d-none d-md-block">{products[0]?.title}</h3>
          {products[0]?.productstatus !== "sold" && (
            <>
              <h3>
              <s style={{ color: '#40C2C9' }}>{formatPrice(products[0]?.msrprice)}</s> / <b>{formatPrice(products[0]?.price)}</b>
              </h3>
            </>
          )}
          <div class="detail-body mb-4">
            <p>
              {/* Description: <br /> */}
              {products[0]?.description}
            </p>
          </div>
        </div>
        {products[0]?.productstatus !== "sold" && (
          <div className="d-flex justify-content-md-between flex-column-reverse flex-md-row mb-3">
            <div></div>
            {loadingBuy === true ? (
              <div
                class="spinner-border text-success"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : user.user_type === "Buyer" || Object.keys(user).length === 0 ? (
              <div className="d-flex gap-1 flex-wrap">
                <>
                  <PayPalScriptProvider options={initialOptions}>
                    {isAuth == false ? (
                      <button
                        className="btn rounded text-white px-2"
                        onClick={() => openModalLogin()}
                        style={{ backgroundColor: "#3ab9c0" }}
                      >
                        <FontAwesomeIcon
                          icon={faShoppingBag}
                          className="pe-2"
                        />{" "}
                        Buy
                      </button>
                    ) : (
                      <div className="w-100">
                        <PayPalButtons
                          style={{
                            shape: "rect",
                            layout: "vertical",
                            label: "buynow",
                          }}
                          createOrder={createOrder}
                          onApprove={onApprove}
                        />
                      </div>
                    )}
                  </PayPalScriptProvider>
                </>
                {/* <button className='btn rounded text-white px-2' onClick={handelBuyProduct} style={{ backgroundColor: '#3ab9c0' }}>
              <FontAwesomeIcon icon={faShoppingBag} className='pe-2' />
              {
                loadingBuy === false ?
                  <>Buy</>
                  :
                  <div class="spinner-border text-success spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
              }
            </button> */}
                <button
                  className="btn rounded text-white px-2"
                  onClick={openModal}
                  style={{ backgroundColor: "#3ab9c0" }}
                >
                  <ChatIcon className="pe-2" />
                  Chat with Seller
                </button>
                <button
                  className="btn rounded text-white px-2"
                  onClick={OpenModelForOffer}
                  style={{ backgroundColor: "#3ab9c0" }}
                >
                  <SellIcon className="pe-2" />
                  Custom Offer
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        <Modal isOpen={isOpenOffer} onClose={closeModalForOffer}>
          <CustomOfferModel
            myid={chatOneId?.toString()}
            partnerid={chatTwoId?.toString()}
            product={products[0]}
            closeModalForOffer={closeModalForOffer}
          />
        </Modal>

        <Modal isOpen={isOpen} onClose={closeModal}>
          <Chat
            myid={chatOneId?.toString()}
            partnerid={chatTwoId?.toString()}
            closeModal={closeModal}
          />
        </Modal>
      </div>

      <Modal isOpen={isOpenLogin} onClose={closeModalLogin}>
        <Login closeModal={closeModalLogin} />
      </Modal>
    </ProductDetailsWrapper>
  );
};

export default ProductDetails;

function Message({ content }) {
  return <p>{content}</p>;
}
