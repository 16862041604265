import styled from "styled-components";

const BidHeaderWrapper = styled.div`
display: flex;
gap: 8px;
padding: 24px 0;
position: sticky;
top: 50px;
.bid-header-main{
  background-color: rgb(38, 38, 38);
  padding: 10px 16px;
  border-radius: 8px;
  width: 100%;
}
.bid-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  & > span{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 18px;
  }
  .text-light{
    color: #fff;
    opacity: 0.7;
  }
  .text-white{
    font-weight: 500;
    color: #fff;
  }
}
.bid-button{
  margin-left: auto;
  white-space: nowrap;
    padding: 8px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: none;
    border-radius: 8px;
    background: #4ad493;
}
`

export default BidHeaderWrapper;