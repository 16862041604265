import React, { useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getinprogressproduct, updateinprogressstatus } from '../../../backendServices/ApiCalls';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/material/styles";
import Swal from 'sweetalert2'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function InprogressProductContent() {
	const [products, setProducts] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const [rowData, setRowData] = useState(false);
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const [selectedStatus, setSelectedStatus] = useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};


	async function fetchUserProducts() {
		getinprogressproduct((response) => {
			if (response?.data?.status === 'success') {
				setProducts(response?.data?.data);
			}
		}, (error) => {
			console.log(error?.response?.data);
		});
	}

	useEffect(() => {
		fetchUserProducts();
	}, []);

	const columns = [
		{
			field: "title",
			headerName: "Product Title",
			width: 150,
			editable: true,
		},
		{
			field: "price",
			headerName: "Price",
			width: 150,
			renderCell: (params) => `$${params.value}`,
		},
		{
			field: "picture",
			headerName: "Product Detail",
			width: 150,
			renderCell: (params) => (
				<Button
					variant="contained"
					onClick={() => handleOpen(params?.row?.id)}
				>
					View
				</Button>
			),
		},
		{
			field: "productstatus",
			headerName: "Status",
			width: 150,
			// renderCell: (params) => `$${params.value}`,
		},
		{
			field: "action",
			headerName: "Action",
			width: 400,
			renderCell: (params) => (
				<>
					<Tooltip title="Delete" placement="top">
						<IconButton
							onClick={() => handleActionClick(params.row.id)}
							sx={{ color: "warning.main" }}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	const gridDesign = {
		"& .MuiDataGrid-toolbarContainer": {
			"& .MuiButton-text": {
				fontSize: "13px !important",
				color: "#f5343e",
			},
			"& .MuiBadge-badge": {
				backgroundColor: "#074682",
			},
			"& .MuiInput-root": {
				borderRadius: 2,
				paddingLeft: 2,
				overflow: "hidden",
			},
		},
	};

	const handleOpen = (id) => {
		navigate(`/product`, { state: { productId: id } })
	};

	const handleActionClick = (id) => {
		const rowToEdit = products.find((row) => row.id === id);
		setRowData(rowToEdit);
		setOpen(true);
	};

	const handleSelectChange = (event) => {
		setSelectedStatus(event.target.value); // Update selected status state when an option is selected
	};

	const handleFormSubmit = async () => {
		let params = {
			productid: rowData.id,
			productstatus: selectedStatus.toLowerCase()
		}
		updateinprogressstatus(params, (response) => {
			setOpen(false);
			if (response?.data?.status === 'success') {
				Swal.fire({
                    title: 'Success',
                    text: response?.data?.message,
                    icon: 'success',
                });
				fetchUserProducts();
			} else if (response?.data?.status === 'error') {
				Swal.fire({
                    title: 'Error',
                    text: response?.data?.message,
                    icon: 'error',
                });
			}
		}, (error) => {
			console.log(error?.response?.data);
		});
	};

	return (
		<div className='mt-3'>
			<h4>Inprogress Products</h4>
			{showAlert && ( // Render Alert component only when showAlert is true
				<Alert
					severity="success"
					onClose={() => setShowAlert(false)} // Set showAlert to false when Alert is closed
				>
					Product deleted successfully.
				</Alert>
			)}
			<div>
				<div className="table-responsive-lg">
					<Box sx={{ height: 500, width: 1 }} textAlign="right">
						{/* <Link to="/add-product">
                <Button variant="contained" sx={{ marginTop: '-50px'}} href="!#">
                        Add Product
                </Button>
          </Link> */}
						{({ isSubmitting }) => (
							<div
								sx={{
									mt: "20%",
									ml: "45%",
									mb: "20%",
								}}
							>
								<CircularProgress />
							</div>
						)}
						<DataGrid
							initialState={{
								pagination: { paginationModel: { pageSize: 6 } },
							}}
							rows={products}
							getRowId={(row) => row.id}
							columns={columns}
							slots={{ toolbar: GridToolbar }}
							sx={gridDesign}
							pageSizeOptions={[6, 12, 18, 24, 30]}
							slotProps={{
								toolbar: {
									showQuickFilter: true,
									quickFilterProps: { debounceMs: 500 },
								},
							}}
						/>
					</Box>
				</div>
			</div>

			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Update status
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers>
					{/* <Typography gutterBottom>
						Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
						magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
						ullamcorper nulla non metus auctor fringilla.
					</Typography> */}
					<form>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12'>
								<div>
									<label>Current Status</label>
									<input type="text" className="form-control" name="prodcutstatus" placeholder="Product Status" value={rowData.productstatus} required />
								</div>
							</div>


							<div className='col-lg-12 col-md-12 col-sm-12 mt-3'>
								<div>
									<label>Select Status</label>
									<select class="form-select" aria-label="Default select example" onChange={handleSelectChange} value={selectedStatus}>
										<option selected>-- select an option --</option>
										<option value="Sold">Sold</option>
										<option value="Deliver">Deliver</option>
									</select>
								</div>
							</div>


							<div style={{ textAlign: 'center' }} className='col-lg-12 col-md-12 col-sm-12 mt-3'>
								<button
									type="button"
									className="btn btn-primary btn-block"
									onClick={handleFormSubmit}
									>
									Submit
								</button>
							</div>
						</div>

					</form>
				</DialogContent>
			</BootstrapDialog>
		</div>
	)
}

export default InprogressProductContent