import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { fireStore } from '../../config/firebase';
import { useAuthContext } from '../../contexts/AuthContext';
import { getuser } from '../../backendServices/ApiCalls';
import Modal from '../../components/pageComponents/modal/Modal';
import Chat from '../../components/pageComponents/Chat/Chat';

export default function NotificationChat() {
    const { user } = useAuthContext()
    const [users, setUsers] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true)

    const getUser = async () => {
        const id = user?.userid?.toString() || '0'
        const q = query(collection(fireStore, "chat"), where("partnerid", "==", id));
        try {
            setLoading(true)
            const querySnapshot = await getDocs(q);
            const fetchedMessages = [];
            querySnapshot.forEach((doc) => {
                const msg = doc.data();
                fetchedMessages.push(msg);
            });
            fetchedMessages.sort((a, b) => a.dateCreated - b.dateCreated);
            // console.log('chat partner', fetchedMessages);

            const separatedData = {};

            fetchedMessages.forEach((obj) => {
                const key = `${obj.uid}_${obj.uid}`;
                if (!separatedData[key]) {
                    separatedData[key] = { uid: obj.uid, objects: [obj] };
                } else {
                    separatedData[key].objects.push(obj);
                }
            });
            const separatedDataArray = Object.values(separatedData);

            // console.log('Separated Data:', separatedDataArray);


            // const partnerIds = separatedDataArray.map((dataObj) => dataObj.uid).toString();



            const partnerIds = separatedDataArray.map((dataObj) => dataObj.uid);
            // Log partnerIds to confirm its structure
            // console.log('partnerIds:', partnerIds);
            let arr = []
            partnerIds.forEach((uid) => {
                getuser({ partnerId: uid }, (response) => {
                    if (response?.data?.status === 'success') {
                        // setUsers((prevUsers) => [...prevUsers, response?.data?.data]);
                        // console.log('response?.data?.data',response?.data?.data)
                        arr.push(response?.data?.data[0])
                        // console.log('arr', arr)
                        setUsers(arr)
                    }
                }, (error) => {
                    console.log(error?.response?.data);
                });
            });


            // getuser({ partnerId: partnerIds }, (response) => {
            //     if (response?.data?.status === 'success') {
            //         // Handle the user data here
            //         // console.log('users success', response?.data?.data);
            //         setUsers(response?.data?.data)
            //     }
            // }, (error) => {
            //     console.log(error?.response?.data);
            // });

        } catch (error) {
            console.log('Error fetching messages:', error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        getUser()
    }, [])
    const setid = (ChatUser) => {
        setSelectedUser(ChatUser);
        // console.log('selectedUser',selectedUser)
        // setIsOpen(true);
        openModal()
    };
    const openModal = () => {
        // setSelectedUser(ChatUser);
        // console.log('selectedUser', selectedUser)
        setIsOpen(true);
    };

    const closeModal = () => {
        setSelectedUser(null);
        setIsOpen(false);
    };
    return (
        <div className="mt-3">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">UserName</th>
                        {/* <th scope="col">id</th> */}
                        <th scope="col">Chat</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users?.map((ChatUser, i) => {
                            return (
                                <tr key={i}>
                                    <td>{ChatUser.username}</td>
                                    {/* <td>{ChatUser.id}</td> */}
                                    <td><button className="btn text-white" style={{ backgroundColor: "#3AB9C0" }} onClick={() => setid(ChatUser.id)}>Chat</button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                loading === true ? (
                    <div class="d-flex justify-content-center">
                        <div className="spinner-border text-success spinner-border-lg" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : users.length === 0 && (
                    <div className='text-center text-secondary'>
                        No Row
                    </div>
                )
            }


            <Modal isOpen={isOpen} onClose={closeModal}>
                {selectedUser && <Chat partnerid={selectedUser?.toString()} myid={user?.userid?.toString()} closeModal={closeModal} />}
            </Modal>
        </div>
    )
}
