import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Frontend from "./Frontend"
// import Auth from "./Auth"
// import Dashboard from "./Dashboard"
// import PrivateRoute from '../components/PrivateRoute'
import { useAuthContext } from '../contexts/AuthContext'
// import Login from './Auth/Login'

export default function Index() {
  const { isAuth } = useAuthContext()
  // console.log('isAuth', isAuth)
  return (
    <>
      <Routes>
        {/* <Route path='/login' element={<Login />} /> */}
        <Route path='/*' element={<Frontend />} />

        {/* <Route path='/account/*' element={<PrivateRoute Component={Frontend} />} /> */}
        <Route path='/*' element={!isAuth ? <Frontend /> : <Navigate to="/" />} />
        {/* <Route path='/account/*' element={isAuth ? <Frontend /> : <Navigate to="/" />} /> */}
        {/* <Route path='/dashboard/*' element={<PrivateRoute Component={Dashboard} />} /> */}
      </Routes>
    </>
  )
}
