import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { fireStore } from "../../../config/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./Chat.css";
import { useAuthContext } from "../../../contexts/AuthContext";
import { getuser } from "../../../backendServices/ApiCalls";
const Chat = ({ myid, partnerid, closeModal }) => {
  // your are alway a myid and the other is always partnerid (!important)
  const { user } = useAuthContext();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [partnerName, setPartnerName] = useState(true);

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds || !timestamp.nanoseconds) {
      return "Invalid Date";
    }

    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().slice(-2);
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const getMsg = async () => {
    setLoading(true);
    try {
      // get real time data
      // const q = query(collection(fireStore, "chat"), where("uid", "==", "1"))
      const q = query(
        collection(fireStore, "chat"),
        where("uid", "in", [myid, partnerid])
      );
      // const q = quer+y(collection(fireStore, "chat"),where('uid', '==', '1'))
      // let q;
      // if (user.user_type == 'myid') {
      //  q = query(collection(fireStore, "chat"), where("uid", "==", myid), where("partnerid", "==", partnerid));
      // } else if(user.user_type == 'partnerid') {
      // let q = query(collection(fireStore, "chat"), where('uid', '==', '1'), where('partnerid', '==', '40'));
      // }

      await onSnapshot(q, (querySnapshot) => {
        const fetchedMessages = [];
        querySnapshot.forEach((doc) => {
          let msg = doc.data();
          fetchedMessages.push(msg);
        });
        // Sort messages by dateCreated in ascending order
        // let sort = fetchedMessages.sort((a, b) => a.dateCreated - b.dateCreated);
        // setMessages(fetchedMessages);
        let tempfilter = fetchedMessages.filter(
          (obj) =>
            (obj.uid === myid && obj.partnerid === partnerid) ||
            (obj.uid === partnerid && obj.partnerid === myid)
        );
        // console.log('tempfilter',tempfilter)

        const sortedMessages = tempfilter.sort((a, b) => {
          const dateA = new Date(a.dateCreated);
          const dateB = new Date(b.dateCreated);
          return dateA - dateB;
        });

        // console.log('sortedMessages',sortedMessages);
        setMessages(sortedMessages);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching messages:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMsg();
    let params = {
      partnerId: partnerid,
    };
    getuser(
      params,
      (response) => {
        console.log(response?.data?.data);
        setPartnerName(response?.data?.data[0]);
        // setLoading(false)
      },
      (error) => {
        console.log(error?.response?.data);
        // setLoading(false)
      }
    );
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const scrollBody = document.getElementById("scroll-body");
    if (scrollBody) {
      scrollBody.scrollTop = scrollBody.scrollHeight;
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === "") {
      // Input is empty, do not send the message
      return;
    }
    try {
      setInput("");
      const docRef = await addDoc(collection(fireStore, "chat"), {
        message: input,
        id: Math.random().toString(36).slice(2),
        dateCreated: getCurrentDate(),
        uid: myid,
        partnerid: partnerid,
      });

      console.log('docRef', docRef);
			// create your custom api to send email
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-4">
          <div className="card" id="chat1" style={{ borderRadius: 15 }}>
            <div
              className="card-header d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
              style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
            >
              {/* <i className="fas fa-angle-left" /> */}
              <div></div>
              <p className="mb-0 fw-bold">
                Live chat with {partnerName.username}
              </p>
              <FontAwesomeIcon
                icon={faClose}
                style={{ cursor: "pointer" }}
                onClick={closeModal}
              />
            </div>
            <div className="card-body">
              <div id="scroll-body" className="scroll-body">
                {loading ? (
                  <div className="d-flex flex-row justify-content-start mb-4">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                      alt="avatar 1"
                      style={{ width: 45, height: "100%" }}
                    />
                    <div
                      className="p-3 ms-3"
                      style={{
                        borderRadius: 15,
                        backgroundColor: "rgba(57, 192, 237,.2)",
                      }}
                    >
                      <p className="small mb-0">Loading messages...</p>
                    </div>
                  </div>
                ) : messages.length === 0 ? (
                  <div className="d-flex flex-row justify-content-start mb-4">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                      alt="avatar 1"
                      style={{ width: 45, height: "100%" }}
                    />
                    <div
                      className="p-3 ms-3"
                      style={{
                        borderRadius: 15,
                        backgroundColor: "rgba(57, 192, 237,.2)",
                      }}
                    >
                      <p className="small mb-0">Start chat</p>
                    </div>
                  </div>
                ) : (
                  messages.map((message, i) => {
                    const isCurrentUser = message.uid === myid;
                    const avatarUrl = isCurrentUser
                      ? "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                      : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp";
                    const messageStyle = isCurrentUser
                      ? { backgroundColor: "#fbfbfb" }
                      : { backgroundColor: "rgba(57, 192, 237,.2)" };

                    return (
                      <div
                        key={i}
                        className={`d-flex flex-row justify-content-${
                          isCurrentUser ? "end" : "start"
                        } mb-4`}
                      >
                        {/* {!isCurrentUser && <img src={avatarUrl} alt="avatar" style={{ width: 45, height: "100%" }} />} */}
                        <div
                          className={`p-3 ${
                            isCurrentUser ? "me-3 border" : "ms-3"
                          }`}
                          style={{ borderRadius: 15, ...messageStyle }}
                        >
                          <p className="small mb-0">{message.message}</p>
                          {/* <p className="mb-0" style={{ fontSize: '0.5rem' }}>{formatDate(message.dateCreated)}</p> */}
                          <p className="mb-0" style={{ fontSize: "0.5rem" }}>
                            {message.dateCreated}
                          </p>
                        </div>
                        {/* {isCurrentUser && <img src={avatarUrl} alt="avatar" style={{ width: 45, height: "100%" }} />} */}
                      </div>
                    );
                  })
                )}
              </div>
              {/* <div className="form-outline d-flex gap-1">
                                <input value={input} onChange={(e) => setInput(e.target.value)} type="text" className="form-control" id="textAreaExample" placeholder="Type your message" />
                                <button className="btn btn-light" onClick={sendMessage}>Send</button>
                            </div> */}
              <form onSubmit={sendMessage}>
                <div className="form-outline d-flex gap-1">
                  <input
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    type="text"
                    className="form-control"
                    id="textAreaExample"
                    placeholder="Type your message"
                  />
                  <button type="submit" className="btn btn-light">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
