import styled from "styled-components";

const BannerSliderWrapper = styled.div`
a{
  text-decoration: none;
}
.main-product-img-wrapper{
  position: relative;
}
.product-img{
    width: 100%;
    // height: 330px;
    height: 30rem;
    object-fit: cover;
    position: relative;
  }
.product-img-sm{
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3/2;
  @media(min-width: 992px){
    height:163.3px;
  }
}
.modal-title{
  color: rgb(255, 255, 255);
  font-size: 18px;
  padding-bottom: 4px;
}
.badge{
  padding: 6px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
.featured-badge{
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(38, 38, 38);
  position: absolute;
  z-index: 3;
}
.no-reserve-badge{
  background-color: #5da1ff;
  color: #fff;
}

.inspected-badge{
    background-color: #e4e7ed;
    color: #262626;
    margin-left: 4px;
  }
.badge-left{
  top: 8px;
  left: 8px;
}
.badge-right{
  top: 8px;
  right: 8px;
}
.title-wrapper{
  position: absolute;
  top: 8px;
  right: 8px;
  width: 100%;
  text-align: end;
}
.slide-details{
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: #262626;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  .icon{
    height: 12px;
    width: 12px;
  }
  .text-light{
    opacity: .7;
  }
  span{
    color: #fff;
    font-size: 14px;
  }
}
`

export default BannerSliderWrapper;