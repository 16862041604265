import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { emailVerify } from '../../../backendServices/ApiCalls'
import Swal from 'sweetalert2'

export default function EmailVerify() {
    const paramsLocation = useParams()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const emailVerifyFun = () => {
        setLoading(true)
        let params = {
            token: paramsLocation.token,
            email: paramsLocation.email
        }
        emailVerify(params, (response) => {
            const { status, data } = response?.data
            if (status === 'success') {
                setTimeout(() => {
                    navigate('/')
                }, 2000);
            } else {
                Swal.fire({
                    text: "something went wrong",
                    icon: 'error',
                });
            }
            setLoading(false)
        }, (error) => {
            console.log(error?.response?.data);
            setLoading(false)
        })
    }

    useEffect(() => {
        setTimeout(() => {
            emailVerifyFun()
        }, 1000);
    }, [])

    return (
        <div className='text-center m-5'>
            <div className='bg-light p-5'>
                <p>Email Verified</p>
                <p>You will be redirected</p>
                {
                    loading &&
                    <div className="spinner-border text-success spinner-border-lg" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
            </div>
        </div>
    )
}
