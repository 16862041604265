import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import GalleryWrapper from './style';
import Star from "../../../assets/star-icon.svg";
import Share from "../../../assets/share-icon.svg";
import LeftArrow from "../../../assets/arrow-left.svg";
import RightArrow from "../../../assets/arrow-right.svg";
import CarImg1 from "../../../assets/car-img-1.jpeg";
import CarImg2 from "../../../assets/car-img-2.jpeg";

const ProductGallery = ({ products, picturelink, }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageLinks, setImageLinks] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);

  useEffect(() => {
    if (products.length > 0) {
      const imagesData = JSON.parse(products[0].images);
      const imageNames = imagesData.imageNames;
      const imageLinksArray = imageNames.map(imageName => `${picturelink}${imageName}`);
      console.log('imageLinksArray', imageLinksArray)
      setImageLinks(imageLinksArray);
    }
  }, [products]);

  // const openImageModal = (imageUrl, openedByButton = false) => {
  //   setSelectedImage(imageUrl);
  //   setShowModal(true);
  //   if (openedByButton) {
  //     setShowPreviewModal(false);
  //   } else {
  //     setShowPreviewModal(true);
  //   }
  // };

  // const closeImageModal = () => {
  //   setSelectedImage(null);
  //   setShowModal(false);
  // };

  // const openPreviewModal = (imageUrl, index) => {
  //   setSelectedImage(imageUrl);
  //   setCurrentIndex(index);
  //   setShowPreviewModal(true);
  // };

  // const closePreviewModal = () => {
  //   setShowPreviewModal(false);
  // };

  // const parsedData = products[0]?.images ? JSON.parse(products[0]?.images) : null;

  // const interiorImages = parsedData?.imageNames.map(imageName => picturelink + imageName);

  // const exteriorImages = parsedData?.imageNames.map(imageName => picturelink + imageName);

  // const exteriorCount = exteriorImages.length;
  // const interiorCount = interiorImages.length;

  // const mainImage = exteriorImages.length > 0 ? exteriorImages[0] : interiorImages[0];
  // const mainGalleryImages = [...exteriorImages.slice(1, 5), ...interiorImages.slice(0, 4)];

  // const handleTabSelect = (category) => {
  //   setSelectedCategory(category);
  // };

  // const handleNext = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % mainGalleryImages.length);
  //   setSelectedImage(mainGalleryImages[(currentIndex + 1) % mainGalleryImages.length]);
  // };

  // const handlePrev = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === 0 ? mainGalleryImages.length - 1 : prevIndex - 1));
  //   setSelectedImage(mainGalleryImages[(currentIndex === 0 ? mainGalleryImages.length - 1 : currentIndex - 1)]);
  // };
  // const remainingImagesCount = exteriorImages.length + interiorImages.length - mainGalleryImages.length;
  // console.log('mainGalleryImages',mainGalleryImages)

  return (
    <>
      <div className="container-fluid">
        <h4 className='d-block d-md-none mb-4'>{products[0]?.title}</h4>
        <div className='d-flex'>
          <div className="col-3 col-lg-1">
            <div className="d-flex flex-column row-gap-3">
              {imageLinks.map((imageUrl, index) => (
                <div style={{ width: '80%' }}>
                  <img className={`secondary-im rounded ${hoveredImage === imageUrl ? 'border border-2 border-success' : ''}`} style={{ width: '100%', cursor: 'pointer' }} onMouseEnter={() => setHoveredImage(imageUrl)} key={index} src={imageUrl} alt={`Thumbnail ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-9 col-lg-10">
            <img className='rounded mb-3 custom-width custom-height' src={hoveredImage || (imageLinks.length > 0 ? imageLinks[0] : '')} alt="Main" style={{ objectFit: 'cover' }} />
            <style>
              {`@media screen and (max-width: 992px) {
                  .custom-width {
                    width: 100%;
                  }}
                    .custom-height{
                  height: 20rem;
                  }
                  @media screen and (max-width: 992px){
                  .custom-height{
                  height: auto;
                  }}
                  `
              }
            </style>
          </div>
        </div>
      </div>
      {/* <GalleryWrapper> */}
      <Container>
        {/* <div className='gallery'>
          <div className="main-gallery">
            <Row className='g-1'>
              <Col lg={7}>
              <img src={mainImage} className='main-img' alt="Main Image" onClick={() => openImageModal(mainImage)} />
              </Col>
              <Col>
                <Row className='g-1 position-relative'>
                  {imageLinks.map((imageUrl, index) => (
                    <Col lg={6} style={{width:'50%'}}>
                      <img className='secondary-img' key={index} src={imageUrl} alt={`Thumbnail ${index + 1}`} onClick={() => openImageModal(imageUrl)} />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </div> */}

        {/* Image modal */}
        {/* {selectedImage && (
          <div className="image-modal" onClick={closeImageModal}>
            <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
              <div className='tabs-wrapper'>
                <Nav variant="tabs" activeKey={selectedCategory} onSelect={handleTabSelect}>
                  <Nav.Item>
                    <Nav.Link eventKey="all">All</Nav.Link>
                  </Nav.Item>
                </Nav>
                <button className="close-btn" onClick={closeImageModal}>x</button>
              </div>
              {(selectedCategory === 'interior' || selectedCategory === 'all') && (
                <div className="image-container">
                  <Row className='g-3 mb-3'>
                    {interiorImages.map((imageUrl, index) => (
                      <Col lg={2}>
                        <img key={index} src={imageUrl} alt={`Interior Image ${index + 1}`} onClick={() => openPreviewModal(imageUrl, index)} />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              {(selectedCategory === 'exterior' || selectedCategory === 'all') && (
                <div className="image-container">
                  <Row className='g-3 mb-3'>
                    {exteriorImages.map((imageUrl, index) => (
                      <Col lg={2}>
                        <img key={index} src={imageUrl} alt={`Exterior Image ${index + 1}`} onClick={() => openPreviewModal(imageUrl, index)} />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </div>
          </div>
        )} */}

        {/* {selectedImage && showPreviewModal && (
          <div className="preview-modal" onClick={closePreviewModal}>
            <div className="preview-modal-content" onClick={(e) => e.stopPropagation()}>
              <div className='tabs-wrapper z-3'>
                <button className="close-btn" onClick={closePreviewModal}>x</button>
              </div>
              <div className='img-wrapper'>
                <img src={selectedImage} alt="Preview Image" />
              </div>
              <div className="nav-arrows">
                <img src={LeftArrow} height={18} alt="Previous" onClick={handlePrev} />
                <img src={RightArrow} height={18} alt="Next" onClick={handleNext} />
              </div>
            </div>
          </div>
        )} */}
      </Container>

      {/* </GalleryWrapper> */}
    </>
  );
};

export default ProductGallery;
