import React from 'react'

export default function About() {
  return (
    <div>
      <h1 className='text-center'>About Us</h1>
      <p>Welcome to Throtyl, your premier online marketplace for buying and selling used auto parts.
        Whether you're a car enthusiast, a professional mechanic, or simply looking to extend the life of
        your vehicle, Throtyl is here to meet all your auto parts needs with ease and reliability.</p>
      <h4>Our Mission</h4>
      <p>At Throtyl, our mission is to revolutionize the way you buy and sell used auto parts. We aim to
        provide a seamless, efficient, and trusted platform where quality meets affordability. Our goal is
        to make auto maintenance and repair more accessible and economical for everyone.</p>
      <h4>What We Offer</h4>
      <ul>
        <li><b>Extensive Selection</b>: Browse through a vast inventory of used auto parts for a wide
          range of makes and models. From engines and transmissions to interior accessories
          and body parts, you'll find everything you need right here.</li>
        <li><b>Quality Assurance</b>: Every part listed on Throtyl undergoes a thorough inspection
          process to ensure it meets our high standards for quality and functionality. Buy with
          confidence knowing you're getting reliable parts at a fraction of the cost.</li>
        <li><b>Buy and Sell</b>: Not only can you find the parts you need, but you can also sell parts you
          no longer require. Our easy-to-use platform allows you to list items quickly and connect
          with buyers across the country. 10% fee will be withdrawn from total selling price</li>
        <li><b>Competitive Pricing</b>: We understand the importance of affordability. By connecting
          buyers and sellers directly, we eliminate the middleman and offer competitive prices that
          fit your budget.</li>
        <li><b>User-Friendly Experience</b>: Our intuitive website design makes it simple to search for,
          compare, and purchase parts. With detailed listings, clear photos, and user reviews,
          finding the right part has never been easier.</li>
      </ul>
      <h4>Our Community</h4>
      <p>Throtyl is more than just a marketplace; it's a community of car lovers and professionals
        dedicated to helping each other. Join our growing network to share knowledge, tips, and
        experiences. Whether you're a seasoned mechanic or a DIY enthusiast, you'll find valuable
        resources and support within our community.</p>
      <h4>Why Choose Throtyl?</h4>
      <ul>
        <li><b>Trust and Transparency</b>: We prioritize honesty and transparency in every transaction.
          Our platform provides detailed information about each part, including its condition and
          history, so you can make informed decisions.</li>
        <li><b>Customer Support</b>: Our dedicated support team is always ready to assist you with any
          questions or concerns. We strive to provide exceptional customer service and ensure a
          smooth and satisfactory experience.</li>
        <li><b>Sustainability</b>: By choosing used auto parts, you're not only saving money but also
          contributing to environmental sustainability. Reusing parts helps reduce waste and
          promotes a more eco-friendly approach to auto maintenance.</li>
      </ul>
      <p>Thank you for choosing Throtyl. We're excited to be your trusted partner in keeping your vehicle
        running smoothly. Start exploring our marketplace today and discover the convenience and
        value of buying and selling used auto parts with Throtyl.</p>
      <h4>Contact Us</h4>
      <p>Have questions or need assistance? Feel free to reach out to our support team at @throtylusa
        on Instagram. We're here to help!</p>
    </div>
  )
}
