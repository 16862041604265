import styled from "styled-components";

const SidebarWrapper = styled.div`
.sidebar{
  .toggle-btn{
    display: none;
  }
  li{
    list-style-type: none;
    margin-top: 24px;
    a{
      text-decoration: none;
      color: #828282;
      font-size: 14px;
      &.active{
        color: #262626;
        font-weight: 600;
      }
    }
  }
}

.sidebar.open {
  left: 0; /* When sidebar is open, it's moved to the left edge */
}
  }
`

export default SidebarWrapper;