import axios from 'axios';

// const API_BASE_URL = 'http://localhost:8000/user/api';
const API_BASE_URL = 'https://backend.throtyl.com/user/api';

// this is use in ProductDetails component

// export let url = 'http://localhost:8000/api/'
export let url = "https://backend.throtyl.com/api/";

function updateAuthorizationHeader() {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['authorization'] = 'Bearer ' + token;
}
export function registerUser(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/register', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function loginUser(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/login', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateusertype(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/updateusertype', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function uploadproduct(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/uploadproduct', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateproductimages(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/updateproductimages', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function removeproductimages(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/removeproductimages', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateproduct(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/updateproduct', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getuserproducts( callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getuserproducts')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getbuyerproducts(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getbuyerproducts')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update withdrawal get api getsettingsdata

export function getpendingproducts( callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getpendingproducts')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getapprovedproducts( callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getapprovedproducts')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getinprogressproduct( callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getinprogressproduct')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getrejectedproduct( callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getrejectedproduct')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getsoldproducts( callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getsoldproducts')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getpurchasedproducts( callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getpurchasedproducts')
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update product status
export function updateinprogressstatus(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios.post(`${API_BASE_URL}${"/updateinprogressstatus"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update withdrawal get api getsettingsdata
export function getsettingsdata(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/getsettingsdata"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function submitManualPayment(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/submitmanualpayment', params, {



  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authenticate(service, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL, service)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getproduct(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getproduct',)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authUserData(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/userdata', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function roidata(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/roidata', {


  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function lasttransactions(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/lastweektransactions', {


  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function dashBoardApi(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/dashboarddata', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function buyerdashboarddata(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/buyerdashboarddata', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function referralusers(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/referralusers', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function gettotalearning(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/gettotalearning', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getmonthlyexpenses(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getmonthlyexpenses', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function gettotalreferrals(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/gettotalreferrals', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function gettotalreferralsweekly(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/gettotalreferralsweekly', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function gettotalreferralsmonthly(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/gettotalreferralsmonthly', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function gettotalexpenses(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/gettotalexpenses', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getweeklybonus(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getweeklybonus', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function referralbonusreport(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/referralbonussummary', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function userpayouts(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/userpayouts', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function unilevelbonusreport(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/unilevelbonussummary', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function faqdata(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/getfaqs', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function transaction(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/transaction', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function depositApi(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/deposit', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function transferROIAPi(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/transferroi', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function buyContractApi(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/buycontract', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getContractsApi(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/getcontracts', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getUserContractsApi(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/getusercontracts', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function getDepositApi(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/depositreport', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getPayoutData(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/payoutdata', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function payoutrequest(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/payoutrequest', params, {})
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}




export function submitnda(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/submitnda', params, {


  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function invitation(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/sendinvitationlink', params, {


  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function adminwallet(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getadminwallet', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function deployuser(investmentamount, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/deployuser ',
    {
      investmentamount: investmentamount
    }
    , {

    })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function getHierarchyData(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/gethierarchy ', {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getBinaryTreeData(userrandomcode, callback, errorCallback) {
  axios.post(API_BASE_URL + '/getbinarytree', {
    userrandomcode: userrandomcode
  }, {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfileData(formdata, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/updateprofiledata', formdata)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function updateProfilePicture(formdata, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/updateprofilepicture', formdata)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function updateProfilePassword(oldpassword, newpassword, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/updatepassword', {
    oldpassword,
    newpassword
  }, {

  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function requestPasswordReset(email, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/forgetpassword', {
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function investandeearning(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + ' /monthlyinvestandeearning', {


  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getnotifications(callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/getnotifications', {


  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function updatenotificationstatus(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/updatenotificationstatus', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function passwordReset(email, password, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/resetpassword', {
    email,
    password
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function validateEmailToken(token, email, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/validateemailtoken', {
    token,
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function verifyemailaccount(token, email, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/verifyemailaccount', {
    token,
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}





export function getsingledepositwallet(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(`${API_BASE_URL}/${'getsingledepositwallet'}`, params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//get product
export function getallproducts(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getallproducts', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//get product
export function getdashboardsoldproducts(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getdashboardsoldproducts', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getproductsfordemo(callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getproductsfordemo', {
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//get search product
export function getsearchproducts(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(`${API_BASE_URL}/${'getsearchproducts'}`, params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getsingleproduct(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(`${API_BASE_URL}/${'getsingleproduct'}`, params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteproduct(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(`${API_BASE_URL}/${'deleteproduct'}`, params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function postRequest(url, params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + url, params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function buyproduct(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/buyproduct', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function getuser(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/getuser', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function customOffer(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/customoffer', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getcustomOffer(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/customofferget', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function customofferstatus(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/customofferstatus', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// =============================== Stripe Api ==================================


// export function paymentIntent(params, callback, errorCallback) {
//   updateAuthorizationHeader()
//   axios.post(API_BASE_URL + '/create-payment-intent', params)
//     .then(response => {
//       if (callback) {
//         callback(response);
//       }
//     })
//     .catch(error => {
//       if (errorCallback) {
//         errorCallback(error);
//       }
//     });
// }

// export function createStripeAccount(params, callback, errorCallback) {
//   updateAuthorizationHeader()
//   axios.post(API_BASE_URL + '/create-stripe-account', params)
//     .then(response => {
//       if (callback) {
//         callback(response);
//       }
//     })
//     .catch(error => {
//       if (errorCallback) {
//         errorCallback(error);
//       }
//     });
// }


export function createPayPalAccount(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/create-paypal-account', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function emailVerify(params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + '/email-verify', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}