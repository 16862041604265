import styled from "styled-components";

const SellCarFormWrapper = styled.div`

.heading{
  font-weight: 600;
  margin-bottom: 20px;
}
 // Style for the label/button
 label.button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
}

label.button:hover {
  background: #f0f0f0;
  color: #333;
}

input[type="radio"]:checked + label.button {
  background-color: #4ad493;
  border-color: #4ad493;
  color: #fff;
}

input[type="radio"] {
  display: none;
}

.form-wrapper{
  .font-light{
    color: #828282;
  }
  .form-heading{
    display: flex;
    align-items: center;
    &::before{
      content: "";
    display: inline-block;
    background: #4ad493;
    border-radius: 0 3px 3px 0;
    margin-right: 8px;
    width: 8px;
    height: 15px;
    }
  }
}
label{
  padding-bottom: 8px;
  padding-top: 20px;
}
input{
  border-radius: 4px;
    height: 38px;
    line-height: 42px;
    padding: 0 0.75rem;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, .2);
    max-width: 160px;
}
textarea{
  border-radius: 4px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, .2);
    width: 100%;
}
.fields-wrapper{
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  button{
    background-color: #4ad493;
    border: none;
    border-radius: 4px;
    height: 38px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
  }
}
.form-box{
  background-color: #f6f6f7;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 24px;
}
.btn{
  background-color: #4ad493;
  border-radius: 4px;
  border: none;
  padding: 10px 24px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

`;

export default SellCarFormWrapper;
