import styled from "styled-components";

const ProfileContentWrapper = styled.div`
padding-top: 60px;
.profile-content-wrapper{
  display: flex;
  gap: 24px;
  align-items: center;
  max-width: 767px;
}
.profile-content{
  display: flex;
  align-items: center;
  gap: 24px;
  img{
    height: 150px;
    width: 150px;
    border-radius: 200px;
    object-fit: cover;
  }
  .user-info-wrapper{
    width: 100%;
  }
  .user-info{
    display: flex;
    flex-direction: column;
    .username{
      font-size: 28px;
      font-weight: 700;
    }
    .subtitle{
      font-size: 14px;
      color: #828282;
    }
  }
}
.edit-btn{
  border: 1px solid rgba(0, 0, 0, .2);
  color: #828282;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
}

.user-content{
  padding: 32px 0;
}

.modal{
  .modal-content{
    .modal-header{
      border-bottom: 0 !important;
    }
  }
}
`

export default ProfileContentWrapper;