// Sidebar.js
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarWrapper from './style';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuthContext } from '../../../contexts/AuthContext';

export default function Sidebar() {
  const [isOpenBtn, setIsOpenBtn] = useState(false);
  const [isOpenProductReport, setIsOpenProductReport] = useState(false);
  const dropdownRef = useRef(null);
  const productReportRef = useRef(null);
  const { user } = useAuthContext()

  const toggleMenu = () => {
    setIsOpenBtn(!isOpenBtn);
  };

  const toggleProductReport = () => {
    setIsOpenProductReport(!isOpenProductReport);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenBtn(false);
    }
    if (productReportRef.current && !productReportRef.current.contains(event.target)) {
      setIsOpenProductReport(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SidebarWrapper>
      <div className='sidebar mt-3'>
        <ul className='d-flex flex-wrap gap-4 d-lg-block ps-1'>
          {/* <li><NavLink to="/account/seller-dashboard">{user.user_type} Dashboard</NavLink></li> */}
          {/* <li><NavLink to="/account/listings" activeClassName="active">My Listings</NavLink></li>
          <li><NavLink to="/account/settings" activeClassName="active">Settings</NavLink></li> */}
          {
            user.user_type === 'Seller' ?
              <>
                <li><NavLink to="/account/seller-dashboard">{user.user_type} Dashboard</NavLink></li>
                <li ref={dropdownRef}>
                  <div className="dropdown">
                    <button className="dropdown-toggle btn p-0 text-secondary" onClick={toggleMenu}>
                      Product
                    </button>
                    <ul className={`dropdown-menu ${isOpenBtn ? 'show' : ''} p-2`}>
                      <NavLink to="/account/add" activeClassName="dropdown-item pb-2" style={{ display: 'block' }} onClick={toggleMenu}>Add</NavLink>
                      <NavLink to="/account/manage" activeClassName="dropdown-item pt-1" style={{ display: 'block' }} onClick={toggleMenu}>Manage</NavLink>
                    </ul>
                  </div>
                </li>
                <li ref={productReportRef}>
                  <div className="dropdown">
                    <button className="dropdown-toggle btn p-0 text-secondary" onClick={toggleProductReport}>
                      Product Report
                    </button>
                    <ul className={`dropdown-menu ${isOpenProductReport ? 'show' : ''} p-2`}>
                      <NavLink to="/account/pending-product" activeClassName="active dropdown-item pb-2" style={{ display: 'block' }} onClick={toggleProductReport}>Pending Product</NavLink>
                      <NavLink to="/account/approved-product" activeClassName="active dropdown-item pt-3" style={{ display: 'block' }} onClick={toggleProductReport}>Approved Product</NavLink>
                      <NavLink to="/account/rejected-product" activeClassName="active dropdown-item pt-3" style={{ display: 'block' }} onClick={toggleProductReport}>Rejected Product</NavLink>
                      <NavLink to="/account/inprogress-product" activeClassName="active dropdown-item pt-3" style={{ display: 'block' }} onClick={toggleProductReport}>Inprogress Product</NavLink>
                      <NavLink to="/account/sold-product" activeClassName="active dropdown-item pt-3" style={{ display: 'block' }} onClick={toggleProductReport}>Sold Product</NavLink>
                    </ul>
                  </div>
                </li>
              </>
              :
              <>
                <li><NavLink to="/account/buyer-dashboard">{user.user_type} Dashboard</NavLink></li>
                <li><NavLink to="/account/manage">My Parts</NavLink></li>
                <li><NavLink to="/account/purchased-product">Order History</NavLink></li>
              </>
          }
          <li><NavLink to="/account/notification">Notifications</NavLink></li>
          <li><NavLink to="/account/customOffer">Custom Offers</NavLink></li>
          <li><NavLink to="/account/profile" activeClassName="active">Profile</NavLink></li>
        </ul>
      </div>
    </SidebarWrapper>
  );
}
