import React, { useEffect, useRef, useState } from "react";
import HeaderWrapper from "./style";
import Logo from "../../../assets/logo.png";
import headerBtn from "../../../assets/headerbtn.png";
import SignUp from "./SignUp";
import SellCar from "./SellCar";
import Login from "./Login";
import Modal from "../modal/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import {
  getallproducts,
  getsearchproducts,
  loginUser,
  updateusertype,
} from "../../../backendServices/ApiCalls";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCar,
  faHamburger,
  faThList,
} from "@fortawesome/free-solid-svg-icons";
import { useProductContext } from "../../../contexts/ProductContext";
import Swal from "sweetalert2";

const NavbarComponent = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenSignup, setIsOpenSignup] = useState(false);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false); // State to manage the navbar collapse
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth, dispatch, user } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [picturelink, setPictureLink] = useState([]);
  // const [products, setProducts] = useState([]);
  const navbarRef = useRef(null);
  const {
    productsContext,
    picturelinkContext,
    updateProductsContext,
    updatePictureLinkContext,
  } = useProductContext();

  // Effect to add event listener on mount and remove it on unmount
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setNavbarCollapsed(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    setSearchTerm("");
  }, [location]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModalLogin = () => {
    setIsOpenLogin(true);
  };

  const closeModalLogin = () => {
    setIsOpenLogin(false);
  };

  const openModalSignup = () => {
    setIsOpenSignup(true);
  };

  const closeModalSignup = () => {
    setIsOpenSignup(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const logout = () => {
    localStorage.removeItem("token");
    dispatch({ type: "SET_LOGGED_OUT" });
    navigate("/");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === "") {
      navigate("/");
    }
  };

  const handleSearchButton = () => {
    GetsearchProduct(searchTerm);
  };

  const GetsearchProduct = (productTitle) => {
    setLoading(true);
    let params = { title: productTitle };
    getsearchproducts(
      params,
      (response) => {
        // console.log('response?.data?.data', response?.data?.data)
        // setProducts(response?.data?.data);
        // setPictureLink(response?.data?.imageURL);
        updateProductsContext(response?.data?.data);
        updatePictureLinkContext(response?.data?.imageURL);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(true);
      }
    );
  };

  const handleNavbarToggle = () => {
    setNavbarCollapsed(!navbarCollapsed);
  };

  const handleButtonClick = (role) => {
    setIsProcessing(true);
    let params = {
      userType: role,
    };

    updateusertype(
      params,
      (response) => {
        let { status, user } = response?.data;
        if (status === "success") {
          dispatch({ type: "SET_LOGGED_IN", payload: { user } });
          if (user.user_type === "Seller") {
            navigate("/account/seller-dashboard");
          } else {
            navigate("/account/buyer-dashboard");
          }
          // window.location.reload();
        } else if (status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
        }
        setIsProcessing(false);
      },
      (error) => {
        setIsProcessing(false);
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <>
      <Navbar className="bg-body-tertiary d-block d-lg-none">
        <Container>
          <Navbar.Brand className="m-0">
            <Link
              className="navbar-brand active d-flex align-items-center"
              to="/"
            >
              <img
                style={{ width: "auto", height: "70px" }}
                className="me-2 me-md-2"
                src={Logo}
                alt="logo"
              />
              <span className="d-none d-md-block">
                THE AUTO <br className="d-md-none d-lg-block d-xl-none" /> PARTS
                MARKETPLACE
              </span>
              <span
                className="d-block d-md-none logo-text-md"
                style={{ fontSize: "20px", display: "inline-block" }}
              >
                THE AUTO PARTS MARKETPLACE
              </span>
            </Link>
            <style>
              {`@media screen and (max-width: 430px) {
                  .logo-text-md {
                    font-size: 12px !important;
                    font-weight:bold;
                  }}
                    @media screen and (max-width: 256px) {
                  .logo-text-md {
                    font-size: 9px !important;
                    font-weight:bold;
                  }}
                  `}
            </style>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {/* <Navbar.Text> */}
            <p
              className="mb-0 ms-0 ms-md-2 d-none d-sm-block"
              style={{ fontWeight: "500" }}
            >
              Buy & Sell Auto Parts
            </p>
            {/* </Navbar.Text> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar
        expand="lg"
        className="bg-body-white border-bottom"
        ref={navbarRef}
      >
        <Container className="d-flex flex-row-reverse flex-lg-row">
          <Navbar.Brand className="m-0 d-none d-lg-block">
            <Link
              className="navbar-brand active d-flex align-items-center"
              to="/"
            >
              <img
                style={{ width: "auto", height: "70px" }}
                className="me-2 me-md-2"
                src={Logo}
                alt="logo"
              />
              <span className="d-none d-md-block">
                THE AUTO <br className="d-md-none d-lg-block d-xl-none" /> PARTS
                MARKETPLACE
              </span>
              <span
                className="d-block d-md-none logo-text"
                style={{ fontSize: "15px", display: "inline-block" }}
              >
                THE AUTO PARTS MARKETPLACE
              </span>
            </Link>
            <style>
              {`@media screen and (max-width: 430px) {
                  .logo-text {
                    font-size: 10px !important;
                    font-weight:bold;
                  }}`}
            </style>
          </Navbar.Brand>
          <button
            type="button"
            className="navbar-toggler"
            onClick={handleNavbarToggle}
            aria-controls="basic-navbar-nav"
            aria-expanded={navbarCollapsed ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <img
              src={headerBtn}
              alt="headerBtn"
              style={{ height: "21px", width: "42px" }}
            />
          </button>
          <div
            className="d-block d-lg-none"
            style={{ width: "70%" }}
          >
            {isAuth && (
              <>
                {/* <Nav.Link>
                  <Link
                    to={
                      user.user_type === "Seller"
                        ? "/account/seller-dashboard"
                        : "/account/buyer-dashboard"
                    }
                    onClick={handleNavbarToggle}
                    className="nav-link fw-medium"
                  >
                    Account
                  </Link>
                </Nav.Link> */}
                <div className="d-flex align-items-left justify-content-left" style={{  display: 'flex', alignItems: 'start !important', justifyContent: 'left !important' }}>
                  {user?.user_type === "Seller" && (
                    <>
                      {isProcessing ? (
                        <div
                          className="spinner-border text-success spinner-border-lg"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button type="button" className="nav-link py-3">
                          <span
                            className="rounded p-2 text-white fw-medium"
                            style={{ backgroundColor: "#40C2C9" }}
                            onClick={() => handleButtonClick("Buyer")}
                          >
                            Switch To Buyer
                          </span>
                        </button>
                      )}
                    </>
                  )}
                  {user?.user_type === "Buyer" && (
                    <>
                      {isProcessing ? (
                        <div
                          className="spinner-border text-success spinner-border-lg"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button type="button" className="nav-link py-3">
                          <span
                            className="rounded p-2 text-white fw-medium"
                            style={{ backgroundColor: "#40C2C9" }}
                            onClick={() => handleButtonClick("Seller")}
                          >
                            Switch To Seller
                          </span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            {!isAuth && (
              <div className="d-flex align-items-center justify-content-between">
                <Nav.Link>
                  <button
                    type="button"
                    className="nav-link p-1 rounded p- text-white fw-medium custom-font"
                    style={{ backgroundColor: "#40C2C9" }}
                    onClick={openModalSignup}
                  >
                    <span className="rounded px-1">Sign Up</span>
                  </button>
                </Nav.Link>
                <Nav.Link>
                  <button
                    type="button"
                    className="nav-link p-1 rounded p- text-white fw-medium custom-font"
                    style={{ backgroundColor: "#40C2C9" }}
                    onClick={openModalLogin}
                  >
                    <span className="rounded px-1">Login</span>
                  </button>
                </Nav.Link>
                <Nav.Link>
                  <button
                    type="button"
                    className="nav-link p-1 rounded p- text-white fw-medium custom-font"
                    style={{ backgroundColor: "#40C2C9" }}
                    onClick={openModal}
                  >
                    <span className="rounded px-1">Sell a Part</span>
                  </button>
                </Nav.Link>
              </div>
            )}
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={navbarCollapsed ? "show" : ""}
          >
            <Nav className="me-auto">
              {!isAuth && (
                <Nav.Link>
                  <div type="button" className="nav-link" onClick={openModal}>
                    <span
                      className="rounded p-2 text-white fw-medium d-none d-lg-block"
                      style={{ backgroundColor: "#40C2C9" }}
                    >
                      Sell a Part
                    </span>
                  </div>
                </Nav.Link>
              )}
              <Nav.Link>
                <Link
                  to="/"
                  className="nav-link fw-medium"
                  onClick={handleNavbarToggle}
                >
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/what-is-throtyl"
                  className="nav-link fw-medium"
                  onClick={handleNavbarToggle}
                >
                  What's throtyl?
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/about"
                  className="nav-link fw-medium"
                  onClick={handleNavbarToggle}
                >
                  About Us
                </Link>
              </Nav.Link>
              {isAuth && (
                <Nav.Link className="">
                  <Link
                    to={
                      user.user_type === "Seller"
                        ? "/account/seller-dashboard"
                        : "/account/buyer-dashboard"
                    }
                    onClick={handleNavbarToggle}
                    className="nav-link fw-medium"
                  >
                    Account
                  </Link>
                </Nav.Link>
              )}
              {isAuth && (
                <Nav.Link className="">
                  <button onClick={logout} className="nav-link fw-medium">
                    Logout
                  </button>
                </Nav.Link>
              )}
            </Nav>
            <Form className="d-lg-flex inline align-items-center d-none">
              {!isAuth && (
                <>
                  <Nav.Link>
                    <button
                      type="button"
                      className="nav-link py-3 me-3"
                      onClick={openModalSignup}
                    >
                      <span
                        className="rounded p-2 text-white fw-medium"
                        style={{ backgroundColor: "#40C2C9" }}
                      >
                        Sign Up
                      </span>
                    </button>
                  </Nav.Link>
                  <Nav.Link>
                    <button
                      type="button"
                      className="nav-link py-3"
                      onClick={openModalLogin}
                    >
                      <span
                        className="rounded p-2 text-white fw-medium"
                        style={{ backgroundColor: "#40C2C9" }}
                      >
                        Login
                      </span>
                    </button>
                  </Nav.Link>
                </>
              )}
              {isAuth && (
                <div className="d-none d-lg-block">
                  {user?.user_type === "Seller" && (
                    <>
                      {isProcessing ? (
                        <div
                          className="spinner-border text-success spinner-border-lg"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button type="button" className="nav-link py-3">
                          <span
                            className="rounded p-2 text-white fw-medium"
                            style={{ backgroundColor: "#40C2C9" }}
                            onClick={() => handleButtonClick("Buyer")}
                          >
                            Switch To Buyer
                          </span>
                        </button>
                      )}
                    </>
                  )}
                  {user?.user_type === "Buyer" && (
                    <>
                      {isProcessing ? (
                        <div
                          className="spinner-border text-success spinner-border-lg"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button type="button" className="nav-link py-3">
                          <span
                            className="rounded p-2 text-white fw-medium"
                            style={{ backgroundColor: "#40C2C9" }}
                            onClick={() => handleButtonClick("Seller")}
                          >
                            Switch To Seller
                          </span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
              {/* <p className="mb-0 ms-0 ms-md-2" style={{ fontWeight: "500" }}>
                Buy & Sell Auto Parts
              </p> */}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="d-flex justify-content-around align-items-center my-2 mx-2 d-block d-lg-none">
        <div className="position-relative" style={{ width: "100%" }}>
          <input
            type="text"
            autoComplete="off"
            aria-autocomplete="list"
            aria-controls="react-autowhatever-1"
            className="form-control"
            placeholder="Search for parts (ex. Porsche, Ferrari, Audi)"
            name="search"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ width: "100%", paddingRight: "50px" }} // Add padding to make space for the button
          />
          <button
            type="button"
            onClick={handleSearchButton}
            style={{
              position: "absolute",
              right: "0px",
              top: "0px",
              height: "100%",
              width: "25%",
              backgroundColor: "#40C2C9",
              color: "white",
              border: "none",
              cursor: "pointer",
              borderRadius: "0 4px 4px 0",
            }}
          >
            Search
          </button>
        </div>
        {/* {!isAuth && (
          <Nav.Link className="text-center">
            <div type="button" className="nav-link" onClick={openModal}>
              <span
                className="rounded p-2 text-white fw-medium"
                style={{ backgroundColor: "#40C2C9" }}
              >
                Sell a Part
              </span>
            </div>
          </Nav.Link>
        )} */}
      </div>

      <Modal isOpen={isOpen} onClose={closeModal}>
        <SellCar closeModal={closeModal} />
      </Modal>

      <Modal isOpen={isOpenLogin} onClose={closeModalLogin}>
        <Login closeModal={closeModalLogin} />
      </Modal>

      <Modal isOpen={isOpenSignup} onClose={closeModalSignup}>
        <SignUp closeModal={closeModalSignup} />
      </Modal>
    </>
  );
};

export default NavbarComponent;
