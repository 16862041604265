import styled from "styled-components";

const ProductTabsWrapper = styled.div`
padding-top: 24px;
.tabs-filters{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 24px;
  gap: 8px;
}
.tabs-header-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
  .nav{
    border-bottom: none;
  }
  .nav-link{
    border: none;
    color: #828282;
    padding: 0;
    padding-left: 12px;
    &.active{
      color: #262626;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        border: 1px solid rgb(38, 38, 38);
        border-radius: 4px;
        top: 4px;
        position: relative;
      }
    }
  }
}
.tabs-heading{
  font-weight: 600;
  padding-right: 16px;
}
.dropdown{
  button{
    background-color: transparent;
    color: rgb(38, 38, 38);
    border: 1px solid #e5e5e5;
    padding: 6px 11px;
  }

}
`

export default ProductTabsWrapper;