import React from 'react'
import SellerDashboardContent from '../../components/pageComponents/sellerDashboardContent'

function SellerDashboard() {
  return (
    <div>
      <SellerDashboardContent />
    </div>
  )
}

export default SellerDashboard
