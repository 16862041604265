import styled from "styled-components";

const FooterWrapper = styled.div`

#autos-reviews {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  .review-wrap {
    margin-bottom: 4px;
    padding: 50px 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .section {
      padding-top: 32px;
      border-top: 1px solid #ebebeb;
      &.why {
        ul {
          margin: 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 0;
          padding-left: 0;
          li {
            margin-bottom: 12px;
            font-size: 15px;
            line-height: 20px;

            strong {
              font-size: 16px;
              line-height: 16px;
              letter-spacing: -1px;
              display: inline-block;
              position: relative;
              span{
                position: relative;
                z-index: 2;
              }
              &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                bottom: -2px;
                height: 5px;
                background: #ffe55f;
                border-radius: 2px;
                z-index: 1;
              }
            }
          }
        }
      }

      &.stories {
        span.heading {
          display: inline-flex;
          align-items: flex-end;
        }

        span.blurb {
          padding-top: 2px;
          display: block;
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 16px;
        }
      }

      &.daily {
        padding-bottom: 32px;

        .btn-primary {
          padding-top: 8px;
          padding-bottom: 8px;
          margin-top: 12px;
          background: #fff;
          border: 1px solid #d5d5d5;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #0f2236;
          width: 99px;
          align-self: flex-start;

          &:hover {
            color: #262626;
            text-decoration: none;
            background-color: #30c880;
            border-color: #2dbe7a;
          }
        }
      }
    }
    .review{
      .stars{
        height:20px;
        margin-right: 4px;
      }
      .user{
        font-weight: bold;
        padding-right: 10px;
      }
    }

h6 {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

ul {
  list-style: none;
  margin-bottom: 1rem;
}

input {
  overflow: visible;
}

@media (min-width: 976px) {
    .section {
      flex: 1 1 40%;
    }

    .review-wrap {
      padding: 30px 0 0;
      margin-bottom: 42px;
      flex-direction: row;
    }
  
}

@media (min-width: 769px) {
    .section {
      flex: 1 1 50%;
      &.why {
        padding-right: 5px;
      }
    }
  
}

@media (min-width: 1300px) {
  .section.stories {
    padding-right: 80px;
  }
}



@media (min-width: 769px) {
  .form-control {
    font-size: 15px;
    border-radius: 6px;
    height: 35px;
    line-height: 33px;
  }
}
}







#autos-footer {
  background: #eeeef0;
  .footer-wrap {
    padding: 50px 14px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .section {
    padding-bottom: 40px;
    flex: 1 1 50%;
    ul{
      padding-left: 0;
      list-style: none;
    }
    @media (min-width: 570px) {
      flex: 1 1 33%;
    }

    @media (min-width: 769px) {
      padding-top: 7px;
      flex: 1 1 25%;
    }

    @media (min-width: 976px) {
      flex: 1 1 20%;
    }

    &.social {
      margin-top: -7px;
      padding-bottom: 35px;
      img{
        height: 34px;
        width: 34px;
      }
      .legal a{
        text-decoration: none;
        color: #262626;
        margin-right: 8px;
      }
      ul {
        margin: 0 0 4px -6px;
        display: flex;
      }
    }

    &.brand {
      display: none;

      @media (min-width: 976px) {
        padding-top: 0;
        display: block;
      }
    }

    h6 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #828282;
      text-transform: uppercase;
    }

    .footer-logo {
      @media (min-width: 976px) {
        width: 138px;
        height: 24px;
        display: block;

        img {
          height: 100%;
        }
      }
    }

    .navbar-nav {
      margin-bottom: 0;

      @media (min-width: 769px) {
        flex-direction: column;
      }

      .nav-item {
        .nav-link {
          padding: 0;
          font-weight: 400;
          font-size: 15px;
          line-height: 30px;
          display: inline-block;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          .nav-link {
            text-decoration: underline;
            color: #262626;
          }
        }
      }
    }

    ul li {
      margin-bottom: 12px;
      font-size: 15px;
      line-height: 20px;
    }

    .section.why ul {
      margin: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0;

      strong::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        bottom: -2px;
        height: 5px;
        background: #ffe55f;
        border-radius: 2px;
        z-index: 1;
      }
    }


    .divider {
      width: 100%;
      height: 1px;
      background: #eaeaea;
      margin: 14px 0;
    }

    .copy-right {
      margin-top: 30px;
      font-size: 13px;
      color: #828282;
      text-align: center;

      a {
        color: #828282;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}



`

export default FooterWrapper