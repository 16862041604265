import React from 'react';
import SettingForm from '../../components/pageComponents/settingForm';

const Settings = () => {
  return (
    <div className='mt-lg-5 pt-lg-5'>
      <SettingForm/>
    </div>
  );
};

export default Settings;