import styled from "styled-components";

const ProfileContentWrapper = styled.div`
.modal-header{
  border-bottom: 0;
  .modal-title{
    font-weight: 600;
  }
}

.profile-wrapper{
  display: flex;
  align-items: center;
  gap: 16px;
  .edit-btn{
  border: 1px solid rgba(0, 0, 0, .2);
  color: #828282;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 12px;
  }
  .user{
    height: 100px;
    width: 100px;
    border-radius: 200px;
    object-fit: cover;
  }
}
label{
  font-weight: 600;
}
textarea{
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
}
.modal-footer{
  border-top: 0;
}

.btn-primary{
  background-color: #4ad493;
  color: #212529;
  border: none;
  padding: 8px 12px;
}
`

export default ProfileContentWrapper