import React from 'react'
import RejectedProductContent from '../../components/pageComponents/rejectedProduct'

function Rejectedproduct() {
  return (
    <div>
      <RejectedProductContent />
    </div>
  )
}

export default Rejectedproduct
