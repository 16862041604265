import styled from "styled-components";

const ListingWrapper = styled.div`
padding-top: 64px;
.heading{
  font-weight: 600;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebebeb;
}

.btn-primary{
  background-color: #4ad493;
  color: #212529;
  border: none;
  padding: 12px 20px;
  min-width: 176px;
  border-radius: 6px;
  text-decoration: none;
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
}
@media(max-width: 991px){
  padding-top: 24px;
}
`

export default ListingWrapper;