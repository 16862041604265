import React from 'react';
import ProfileContent from '../../components/pageComponents/profileContent';

const Profile = () => {
  return (
    <div>
      <ProfileContent/>
    </div>
  );
};

export default Profile;