// Product.js
import React, { useEffect, useState } from 'react';
import ProductGallery from '../../components/pageComponents/productGallery';
import { Col, Container, Row } from 'react-bootstrap';
import BidHeader from '../../components/pageComponents/bidHeader';
import ProductSidebar from '../../components/pageComponents/productSidebar';
import ProductDetails from '../../components/pageComponents/productDetails';
import { useLocation, useParams } from 'react-router-dom';
import { getsingleproduct, postRequest } from '../../backendServices/ApiCalls';

const Product = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true)
  const [picturelink, setPictureLink] = useState([])
  const [products, setProducts] = useState([])
  const [customOffer, setCustomOffer] = useState()

  // Ensure location state exists before accessing productId
  // const productIdFromState = location.state ? location?.state?.productId : null;
  const paramsLocation = useParams()
  const productIdFromState = paramsLocation.id

  const GetAllProduct = () => {
    setLoading(true)
    let params = {
      pid: productIdFromState,
    }
    getsingleproduct(params, (response) => {
      setProducts(response?.data?.data)
      setPictureLink(response?.data?.imageURL)
      setLoading(false)
    }, (error) => {
      console.log(error?.response?.data);
      setLoading(false)
    })
    postRequest('/customofferforproduct', params, (response) => {
      setCustomOffer(response?.data?.data[0].offerAmount)
      setLoading(false)
    }, (error) => {
      console.log(error?.response?.data);
      setLoading(false)
    })
  }

  useEffect(() => {
    GetAllProduct();
  }, []);

  if (loading) {
    return (
      <div class="d-flex justify-content-center my-5 pt-5">
        <div className="spinner-border text-success spinner-border-lg" style={{ height: '3rem', width: "3rem" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className='mt-2 pt-1'>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-8">
            <ProductGallery products={products} picturelink={picturelink} />
          </div>
          <div className="col-12 col-md-4">
            <ProductDetails products={products} customOffer={customOffer} picturelink={picturelink} />
          </div>
        </div>
      </div>
      {/* <ProductGallery products={products} picturelink={picturelink} />
      <Container>
        <Row className='position-relativ'>
          <Col lg={7}>
            <ProductDetails products={products} customOffer={customOffer} picturelink={picturelink} />
          </Col>
        </Row>
      </Container> */}
    </div>
  );
};

export default Product;
