import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadproduct } from "../../../backendServices/ApiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function ProductAdd() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    msrprice: "",
    price: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  console.log('user', user.paypal_account);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (files.length + acceptedFiles.length > 5) {
        Swal.fire({
          title: "Error",
          text: "You can only select a maximum of 5 images.",
          icon: "error",
        });
        return;
      }
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const thumbInner = {
    position: "relative",
    minWidth: 0,
    overflow: "hidden",
  };

  const removeIcon = {
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    padding: "4px",
    zIndex: 1,
  };

  // Add appropriate styling to the remove button
  const handleRemoveImage = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  // Render the remove button alongside each image
  const thumbs = files.map((file, index) => (
    <div style={thumb} key={index}>
      <div style={thumbInner}>
        <FontAwesomeIcon
          icon={faTimes}
          className="remove-icon"
          onClick={() => handleRemoveImage(index)}
        />
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Clean up function to revoke object URL
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  // const [formErrors, setFormErrors] = useState({
  //   title: '',
  //   price: '',
  //   description: ''
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let errors = {};
    // Simple validation check
    for (const field in formData) {
      if (formData[field] === "") {
        errors[field] = `${field} field is empty`;
      }
    }
    // Check if less than 4 images are uploaded
    if (files.length === 0) {
      errors.images = "Please upload at least one image.";
      Swal.fire({
        title: "Error",
        text: "Please upload at least one image.",
        icon: "error",
      });
      setLoading(false);
      return; // Prevent form submission if less than 4 images are uploaded
    }

    if (files.length > 5) {
      errors.images = "You can upload maximum 5 images!";
      Swal.fire({
        title: "Error",
        text: "You can upload maximum 5 images!",
        icon: "error",
      });
      setLoading(false);
      return; // Prevent form submission if less than 4 images are uploaded
    }

    // Check if any errors exist
    if (Object.keys(errors).length > 0) {
      return; // Prevent form submission if any field is empty
    }

    // Create a FormData object to send both form data and images
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    // Append each image file to the FormData object
    files.forEach((file, index) => {
      // Generate a unique filename using the current timestamp
      const imageName = `car-img-${Date.now()}-${index}.${file.name
        .split(".")
        .pop()}`;
      formDataToSend.append("images", file, imageName);
    });
    if (user.paypal_account === '' || user.paypal_account === null) {
      Swal.fire({
        title: "Error",
        text: "To upload a product, you first need to add your PayPal account's email address.",
        icon: "error",
      });
      navigate('/account/profile');
      return;
    }

    // Call the registerUser function with the FormData object
    uploadproduct(
      formDataToSend,
      (response) => {
        if (response?.data?.status === "success") {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              // closeModal()
              // navigate('/');
              setFormData({ title: "", price: "", description: "" });
              setFiles([]);
            }
          });
        } else if (response?.data?.status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
        }
        setLoading(false);
        // Handle any further actions after successful upload
      },
      (error) => {
        setLoading(false);
        console.log(error?.response?.data);
        // Handle errors
      }
    );
  };
  return (
    <div className="mt-3">
      <div className="p-4 rounded" style={{ backgroundColor: "#F6F6F7" }}>
        {user.status === "pending" ? (
          <p>Please Wait for Account to be approved.</p>
        ) : (
          <>
            <h4>Upload Product</h4>
            <form onSubmit={handleSubmit}>
              <label>
                Title:
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  required
                  value={formData.title}
                  onChange={handleInputChange}
                />
              </label>
              <br />
              <label>
                MSR Price:
                <input
                  type="number"
                  className="form-control"
                  name="msrprice"
                  required
                  value={formData.msrprice}
                  onChange={handleInputChange}
                />
              </label>
              <br />
              <label>
                Price:
                <input
                  type="number"
                  className="form-control"
                  name="price"
                  required
                  value={formData.price}
                  onChange={handleInputChange}
                />
              </label>
              <br />
              <label>
                Description:
                <textarea
                  name="description"
                  className="form-control"
                  cols="40"
                  required
                  rows="3"
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
              </label>
              <br />
              <div className="mb-3 text-center">
                <div
                  style={{ border: "2px dashed #BBBBBB" }}
                  {...getRootProps({ className: "dropzone p-4" })}
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside style={thumbsContainer}>{thumbs}</aside>
              </div>
              <br />
              <button
                type="submit"
                className="btn text-white"
                style={{ backgroundColor: "#4ad493" }}
              >
                {loading === true ? (
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Upload"
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
