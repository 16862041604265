// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDVyOrdf04wItY35DBTkaptWjvdYhJrc7Y",
  authDomain: "fir-realtime-chat-6cc31.firebaseapp.com",
  databaseURL: "https://fir-realtime-chat-6cc31-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "fir-realtime-chat-6cc31",
  storageBucket: "fir-realtime-chat-6cc31.appspot.com",
  messagingSenderId: "827397833293",
  appId: "1:827397833293:web:52068131aaf33594aad204",
  measurementId: "G-Q7W60Q3Q6Z"
};

// const firebaseConfig = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: process.env.FIREBASE_MEASUREMENT_ID
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const fireStore = getFirestore(app);

export { analytics, fireStore }