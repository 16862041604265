import React, { useState } from "react";
import { useAuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../backendServices/ApiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faLock,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "./signup.css";

// import './SignUp.css'; // Import CSS for custom styling

export default function Login({ closeModal }) {
  const { dispatch } = useAuthContext();
  const [state, setState] = useState({ fullName: "", email: "", password: "" });
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) =>
    setState((s) => ({ ...s, [e.target.name]: e.target.value }));

  const handleLogin = async (event) => {
    event.preventDefault();
    let { email, password } = state;
    setIsProcessing(true);
    let params = {
      username: email.toLowerCase(),
      password,
    };

    loginUser(
      params,
      (response) => {
        let { status, token, user } = response?.data;
        if (status === "success") {
          localStorage.setItem("token", token);
          dispatch({ type: "SET_LOGGED_IN", payload: { user } });
          closeModal();
          if (user.user_type === "Seller") {
            navigate("/account/seller-dashboard");
          } else {
            navigate("/account/buyer-dashboard");
          }
          // window.location.reload();
        } else if (status === "error") {
          Swal.fire({
            title: "Error",
            text: response?.data?.message,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
        }
        setIsProcessing(false);
      },
      (error) => {
        setIsProcessing(false);
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <div
      style={{ backgroundColor: "transparent", border: "none" }}
      className="modal-content buyer"
    >
      <div className="sign-up-container">
        <div className="sign-up-content">
          <div className="modal-header border-bottom-0">
            <button
              onClick={closeModal}
              type="button"
              className="btn-close"
            ></button>
          </div>
          <h2 className="sign-up-title">Sign In</h2>
          {/* <div className='mx-auto' style={{width:'70%'}}>
                        <div className='text-center row gy-2'>
                            <button className='border border-1 btn rounded py-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" width="20px" height="20px" className='me-1'><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
                                Continue with Google
                            </button>
                            <button className='border border-1 rounded py-1 text-white' style={{ backgroundColor: '#0866FF' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25px" height="25px" className='me-1' style={{fill:'white'}}><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
                                Continue with Facebook
                            </button>
                            <button className='border border-1 rounded py-1 text-white bg-dark'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"width="25px" height="25px" className='me-1' style={{fill:'white'}}><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/></svg>
                                Continue with Apple
                            </button>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <hr className='flex-grow-1 border-top border-black' />
                        <span className='px-2'>OR</span>
                        <hr className='flex-grow-1 border-top border-black' />
                    </div> */}
          <form onSubmit={handleLogin}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="email"
                    placeholder="Username"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-group">
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="col-lg-12 col-md-12 col-sm-12"
              >
                {isProcessing ? (
                  <div
                    className="spinner-border text-success spinner-border-lg"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={isProcessing}
                    className="btn btn-primary btn-block"
                  >
                    Sign In
                  </button>
                )}
              </div>
            </div>
          </form>
          {/* <p className="sign-in-link">Don't have an account? <a href="/auth/login">Sign Up</a></p> */}
        </div>
      </div>
    </div>
  );
}
