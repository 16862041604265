import React from 'react';
import ListingWrapper from './style';
import { Link } from 'react-router-dom';

const ListingComponent = () => {
  return (
    <ListingWrapper>
      <h2 className='heading'>My Listings</h2>
      <span className='d-block pb-5 pt-3'>You have not submitted any cars yet. Want to sell your car?</span>
      <Link className='btn-primary' to="/sell-car">Get Started</Link>
    </ListingWrapper>
  );
};

export default ListingComponent;