import styled from "styled-components";

const SettingFormWrapper = styled.div`
padding-top: 64px;
.heading{
  font-weight: 600;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebebeb;
}

.form-wrapper{
  border-bottom: 1px solid #ebebeb;
  padding: 16px 0;
  label{
    font-weight: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }
}

.light-btn{
    font-size: 12px;
    font-weight: 500;
    color: #828282;
    border: 1px solid rgba(0, 0, 0, .2);
    background-color: transparent;
    border-radius: 4px;
    padding: 4px 12px;
  }
.form-line{
  margin-bottom: 4px;
  label{
    font-weight: 400;
    margin-bottom: 4px;
  }
  p{
    color: #828282;
    margin-bottom: 0;
  }
  .dark-btn{
    font-size: 12px;
    font-weight: 500;
    color: #0f2236;
    border: 1px solid rgba(0, 0, 0, .2);
    background-color: #4ad493;
    border: none;
    border-radius: 4px;
    padding: 4px 12px;
    margin-bottom: 6px;
  }
}
.switch{
  height: 40px;
  position: relative;
  input {
  display: block;
  opacity: 0;
  margin-bottom: 0;
  position: absolute;
}

label {
  position: relative;
  width: 60px;
  height: 34px;
  display: inline-block;
  background: #e3e3e3;
  border-radius: 6px;
  margin-bottom: 0;
  cursor: pointer;
  transition: all 0.3s;
}

label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 28px;
  height: 28px;
  margin: 1px;
  background: #FFF;
  border-radius: 4px;
}

input:checked + label {
  background: #4ad493;
}

input:checked + label:after {
  left: auto;
  right: 2px;
}
}


@media(max-width: 991px){
  padding-top: 24px;
}
`

export default SettingFormWrapper;