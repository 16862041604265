import React from 'react'
import PurchasedProducdContent from '../../components/pageComponents/purchasedProduct'

function PurchasedProduct() {
  return (
    <div>
      <PurchasedProducdContent />
    </div>
  )
}

export default PurchasedProduct
